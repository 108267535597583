import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';


const languages = [
    { code: 'en', name: 'English' },
    { code: 'fr', name: 'Français' },
    { code: 'pt', name: 'Português' },
    { code: 'de', name: 'Deutsch' },
    { code: 'it', name: 'Italiano' },
    { code: 'es', name: 'Español' },
  ];
  

const LanguageSwitcher = () => {

  const { i18n } = useTranslation();

    const changeLanguage = (e) => {
        const value = e.target.value;
        Cookies.set('lang', value);
        i18n.changeLanguage(value);
      };

  return (
    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={i18n.language} placeholder="Age" onChange={changeLanguage} style={{width: '100%'}}>
      {languages.map(language => (
        <MenuItem key={language.code} value={language.code}>{language.name}</MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSwitcher;
