import React, { useState } from "react";
import { Stack, styled } from "@mui/system";
import { Box, Button, Typography, TextField, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import axios from "axios";

const CompanyDetailsContainer = styled("div")(({ theme }) => ({
  border: "1px solid #ccc",
  padding: "10px",
  borderRadius: "4px",
  marginTop: "10px",
  width: "100%",
  maxWidth: "500px",
  textAlign: "left",
  fontSize: "16px",
  color: "#443e41",
  lineHeight: "30px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    padding: "8px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "12px",
    padding: "6px",
  },
}));

const SuggestionList = styled(List)(({ theme }) => ({
  maxHeight: "160px",
  overflowY: "auto",
  border: "1px solid #ccc",
  borderRadius: "4px",
  marginTop: "5px",
}));

const CompanyStep = ({ nextStep }) => {
  const { t } = useTranslation();
  const [companyID, setCompanyID] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [clientObject, setClientObject] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null);

  const getAccessToken = async () => {
    return "bc2c48f1-92eb-345e-93cd-16842dd49d47"; 
  };

  const fetchCompanySuggestions = async (query) => {
    try {
      const accessToken = await getAccessToken();
      let response;

      if (query.length === 14 && !isNaN(query)) {
        try {
          response = await axios.get(`https://api.insee.fr/entreprises/sirene/V3.11/siret/${query}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const companyData = response.data.etablissement;
          setSuggestions([companyData]);
          setError(null);
          return;
        } catch (error) {
          console.log("SIRET not found, falling back to name-based suggestions.");
        }
      }

      const encodedQuery = encodeURIComponent(`denominationUniteLegale:"${query}"`);
      response = await axios.get(`https://api.insee.fr/entreprises/sirene/V3.11/siret?q=${encodedQuery}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const allEtablissements = response.data.etablissements;

      if (allEtablissements.length === 0) {
        setSuggestions([]);
        setError(t("signup.step7Professional.errorNotFound"));
        return;
      }

      setSuggestions(allEtablissements);
      setError(null);
    } catch (error) {
      console.error("Error fetching company suggestions:", error);
      setError(t("signup.step7Professional.errorNotFound"));
      setSuggestions([]);
    }
  };

  const fetchCompanyDetails = async (siret) => {
    try {
      const accessToken = await getAccessToken();
      const response = await axios.get(`https://api.insee.fr/entreprises/sirene/V3.11/siret/${siret}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const companyData = response.data.etablissement;

      setCompanyDetails({
        name: companyData.uniteLegale.denominationUniteLegale || companyData.uniteLegale.nomUniteLegale,
        address: companyData.adresseEtablissement 
          ? `${companyData.adresseEtablissement.numeroVoieEtablissement} ${companyData.adresseEtablissement.typeVoieEtablissement} ${companyData.adresseEtablissement.libelleVoieEtablissement}, ${companyData.adresseEtablissement.codePostalEtablissement} ${companyData.adresseEtablissement.libelleCommuneEtablissement}`
          : t("signup.step7Professional.noAddressAvailable"),
        activity: companyData.uniteLegale.activitePrincipaleUniteLegale,
        creation: companyData.dateCreationEtablissement || companyData.uniteLegale.dateCreationUniteLegale,
        siret: companyData.siret,
      });
      setIsButtonEnabled(true);
      setError(null);
    } catch (error) {
      console.error("Error fetching company details:", error);
      setError(t("signup.step7Professional.errorNotFound"));
      setCompanyDetails(null);
      setIsButtonEnabled(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCompanyID(value);
    setCompanyDetails(null);
    setIsButtonEnabled(false); 
    if (value.length > 0) {
      fetchCompanySuggestions(value);
    } else {
      setSuggestions([]);
      setError(null);
    }
  };

  const handleSuggestionClick = (siret) => {
    setSuggestions([]);
    fetchCompanyDetails(siret);
  };

  const handleNextClick = async () => {
    console.log("Next step with company details:", companyDetails);
    nextStep({ company_id: companyID, company_name: companyDetails.name, address_line_1: companyDetails.address });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Typography variant="h2">{t("signup.step7Professional.title2")}</Typography>
      <div style={{ marginTop: 30 }}>
        <Box position="relative">
          <Box sx={{ maxWidth: 600 }}>
            <TextField
              label={t("signup.step7Professional.companyInput")}
              type="text"
              value={companyID}
              onChange={handleInputChange}
              error={!!error}
              helperText={error || ""}
              fullWidth
            />
            {suggestions.length > 0 && (
              <SuggestionList component="nav" aria-label="company suggestions">
                {suggestions.map((suggestion) => (
                  <ListItem
                    button
                    key={suggestion.siret}
                    onClick={() => handleSuggestionClick(suggestion.siret)}
                  >
                    <ListItemText
                      primary={`${suggestion.uniteLegale.denominationUniteLegale || suggestion.uniteLegale.nomUniteLegale} (${suggestion.siret})`}
                    />
                  </ListItem>
                ))}
              </SuggestionList>
            )}
          </Box>
          {companyDetails && (
            <CompanyDetailsContainer>
              <div>
                <strong>{t("signup.step7Professional.name")}:</strong> {companyDetails.name}
              </div>
              <div>
                <strong>{t("signup.step7Professional.address")}:</strong> {companyDetails.address}
              </div>
              <div>
                <strong>{t("signup.step7Professional.activity")}:</strong> {companyDetails.activity}
              </div>
              <div>
                <strong>{t("signup.step7Professional.creation")}:</strong> {companyDetails.creation}
              </div>
              <div>
                <strong>{t("signup.step7Professional.siret")}:</strong> {companyDetails.siret}
              </div>
            </CompanyDetailsContainer>
          )}
        </Box>

        <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2}>
          <Button
            disabled={!isButtonEnabled}
            variant="contained"
            color="primary"
            style={{ width: 250 , marginTop:"20px" }}
            onClick={handleNextClick}
          >
            {t("buttons.next")}
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default CompanyStep;
