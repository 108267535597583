import serviceLinks from "../common/Links.json"
import ApiHelper from "../common/ApiHelper";
import Headers from "../common/types/Headers";
import Constants from "../common/Constants";
const headers = new Headers();

class ClaimService {
    static getMyClaimList() {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = serviceLinks.getMyClaimList;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static getClaimDetailByClaimId(claimId) {
        if(claimId){
            const url = serviceLinks.getClaimDetailByClaimId + claimId;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }
    static createNewClaim(params) {
        if(params){
            const url = serviceLinks.createNewClaim;
            // let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('POST', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }
}

export default ClaimService;