import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MainCard from 'components/MainCard';
import dateFormat from "dateformat";
import { useTranslation } from 'react-i18next';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ScrollX from 'components/ScrollX';
import { DraggableColumnHeader } from 'components/third-party/react-table';
import { format as currencyFormatter } from 'currency-formatter'
export default function ExpandingPolicyDetail({ data }) {
  const { t } = useTranslation();
  const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const renderPolicyStatus = (value) => {
    switch (value) {
      case 'EXPIRED':
        return <Chip color="error" label={t('policies.policyStatus.expired')} size="Default" variant="light" />;
      case 'ACTIVE':
        return <Chip color="success" label={t('policies.policyStatus.active')} size="Default" variant="light" />;
      default:
        return <Chip color="primary" label={t(`policyStatus.${value?.toLowerCase()}`)} size="Default" variant="light" />;
    }
  };

  console.log("Data passed to ExpandingPolicyDetail:", data);

  if (!data) {
    return <div>{t('policies.noPolicyData')}</div>; // Render a fallback if data is null or undefined
  }

  return (
    <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }}>
      <Grid item xs={12} sm={5} md={4} xl={3.5}>
        <MainCard title={t('policies.policyDetails')}>
          <List sx={{ py: 0 }}>
            <ListItem divider={!matchDownMD}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.policyNumber')}</Typography>
                    <Typography variant='h3'>
                      {data.amendment_number}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem divider={!matchDownMD}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.status')}</Typography>
                    <Typography>{data.policy_status_id && renderPolicyStatus(data.policy_status_id)}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem divider={!matchDownMD}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.registrationDate')}</Typography>
                    <Typography>{dateFormat(data.start_date, 'yyyy-mm-dd')}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.expirationDate')}</Typography>
                    <Typography>{dateFormat(data.end_date, 'yyyy-mm-dd')}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem divider={!matchDownMD}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={0.5}>
                    <Typography color="secondary">{t('policies.paymentFrequency')}</Typography>
                    <Typography>{data.payment_frequency}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={7} md={8} xl={8.5}>
        <Stack spacing={2.5}>
          <MainCard title={t('policies.additionalPolicyDetails')}>
            <List sx={{ py: 0 }}>
              {/* <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.policyHolder')}</Typography>
                      <Typography variant="h3">{data.policy_holder ?? t('policies.noData')}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.coverageAmount')}</Typography>
                      <Typography variant="h3">{data.coverage_amount ?? t('policies.noData')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.premiumAmount')}</Typography>
                      <Typography>
                        {data.premium_amount ? `${data.premium_amount} ${data.currency}` : t('policies.noData')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.policyType')}</Typography>
                      <Typography>{data.policy_type ?? t('policies.noData')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.policyDescription')}</Typography>
                      <Typography>{data.description ?? t('policies.noData')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem> */}
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.maxCompensationPerClam')}</Typography>
                      <Typography>{currencyFormatter(data.maximum_amount_of_compensation_per_claim, { code: 'EUR' })}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('policies.numberClaimPerYear')}</Typography>
                      <Typography>{data.number_of_claim_per_year}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ScrollX>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: 150 }}>{t('policies.risks.risk_label')}</TableCell>
                        <TableCell>{t('policies.risks.risk_description')}</TableCell>
                        <TableCell>{t('policies.risks.price_per_month')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.risks_rev.map(risk =>
                        <TableRow>
                          <TableCell>{risk.risk_label}</TableCell>
                          <TableCell>{risk.description}</TableCell>
                          <TableCell>{currencyFormatter(risk.price, { code: 'EUR' })}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ScrollX>
            </List>

          </MainCard>
        </Stack>
      </Grid>
    </Grid>
  );
}

ExpandingPolicyDetail.propTypes = { data: PropTypes.object.isRequired };
