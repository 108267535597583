import axios from "axios";
import Utils from "../utils/Utils";

export default function ApiHelper(method, api_url, data, headers) {
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: `${Utils.GetApiBaseUrl()}${api_url}`,
            data: data,
            headers: headers
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            console.log(`Error when request API ${api_url}:`)
            if(err.response) {
                console.log(err.response)
                reject(err.response)
            }
            else {
                err.code = "ERR_NETWORK"
                reject('Could not connect to server. Please try again later.')
            }           
        })
    })
}