// project-imports
import AuthLayout from 'layouts/Auth';
import Login from 'pages/frontOffice/auth/login';
import ForgotPassword from 'pages/frontOffice/auth/forgot-password';
import ResetPassword from 'pages/frontOffice/auth/reset-password';
import CodeVerification from 'pages/frontOffice/auth/code-verification';
import CheckMail from 'pages/frontOffice/auth/check-mail';

// render - login


// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          path: '/login',
          element: <Login />
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        {
          path: 'reset-password',
          element: <ResetPassword />
        },
        {
          path: 'check-mail',
          element: <CheckMail />
        },
        {
          path: 'code-verification',
          element: <CodeVerification />
        }
      ]
    }
  ]
};

export default LoginRoutes;
