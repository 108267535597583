import React, { useEffect, useState } from 'react';
import { height, styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../components/InputComponent';
import Slider from '@mui/material/Slider';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Link, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { TickCircle } from 'iconsax-react';
import { fetcher, fetcherPost } from 'utils/axios';
import { RemoveCircle } from '@mui/icons-material';
import { format as currencyFormatter } from 'currency-formatter'
import IconButton from 'components/@extended/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

const SliderContainer = styled('div')({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '10px',
  color: '#EB3D9F',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '17px',
  lineHeight: '18px',
  letterSpacing: '0.5px',
  marginLeft: '15%',
  '@media (max-width: 1370px)': {
    width: '80%',
    marginLeft: '10%',
  },
  '@media (max-width: 955px)': {
    width: '90%',
    marginLeft: '5%',
    marginTop: '0px',
  },
});

const price = {
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: 1,
};

// Temporary use icon for package on FE
const ICON_PACKAGES = {
  0: <HomeIcon />,
  1: <WorkIcon />,
  2: <FamilyRestroomIcon />,
};

const Step9 = ({ nextStep, prevStep, userType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [risks, setRisks] = useState([]);
  const productId = '19edb627-9ddb-4df4-bc60-8ac4ac8c2e37'; // TODO: replace with actual product ID
  const [sliderValue, setSliderValue] = useState(500);
  const [sliderValue2, setSliderValue2] = useState(3);
  const translationKey = userType === 'PERSONAL' ? 'signup.step9personal' : 'signup.step9professional';
  const [open, setOpen] = useState(false);
  const [explanation, setExplanation] = useState();

  useEffect(() => {
    getRiskObjects();
  }, []);

  const generateQuotation = () => {
    const quotation = {
      product_id: productId,
      maximum_amount_of_compensation_per_claim: sliderValue,
      number_of_claim_per_year: sliderValue2,
      risk_ids: selected,
      total_amount: calculateTotal(),
    };
    return quotation;
  };

  const createQuotation = async (quote) => {
    const request = await fetcherPost([`/quotes/`, quote]);
    navigate(`/quotation/${request.quote_code}`);
  };

  const getRiskObjects = async () => {
    let request = await fetcher(`/risks/list-by-product-id/${productId}`);
    setRisks(request);
    request.map((risk) => handleSelect(risk.id));
  };

  const handleSelect = (id) => {
    setSliderValue2(3);
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  const handleNextClick = async () => {
    let quote = generateQuotation();
    await createQuotation(quote);
  };

  const calculateTotal = () => {
    let totalPrice = 0;
    selected.map((risk_id) => {
      risks
        .filter((risk) => risk.id === risk_id)
        .map((risk) => {
          totalPrice += parseInt(risk.price);
          return totalPrice;
        });
      return totalPrice;
    });
    return totalPrice;
  };

  const CustomSlider = () => {
    const [sliderValue, setSliderValue] = useState(500);

    const transformValue = (value) => {
      switch (value) {
        case 0:
          return 500;
        case 50:
          return 1000;
        case 100:
          return 2000;
        default:
          return value;
      }
    };

    const reverseTransformValue = (value) => {
      switch (value) {
        case 500:
          return 0;
        case 1000:
          return 50;
        case 2000:
          return 100;
        default:
          return value;
      }
    };

    return (
      <Slider
        value={reverseTransformValue(sliderValue)}
        min={0}
        max={100}
        marks={[
          { value: 0, label: '500 €' },
          { value: 50, label: '1000 €' },
          { value: 100, label: '2000 €' },
        ]}
        step={null}
        onChange={(e, value) => {
          const transformedValue = transformValue(value);
          if (transformedValue !== 1500) {
            setSliderValue(transformedValue);
          }
        }}
      />
    );
  };

  const normalizeText = (text) => text.toLowerCase().replaceAll(' ', '-');
  const handleOpen = (keyword) => {
    setExplanation({ label: keyword, content: t(t(`signup.step9.${normalizeText(keyword)}`)) });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <List>
        <ListItem sx={{ px: 0 }}>
          <Grid container spacing={3}>
            {risks.map((risk, index) => (
              <Grid item xs={12} md={4} key={index} style={{ textAlign: 'center' }}>
                <MainCard border={true}  sx={{
    px: 0,
    border: selected.includes(risk.id) ? '2px solid' : 'none',
    borderColor: selected.includes(risk.id) ? 'primary.main' : 'transparent',
    height:"100%"
  }}>
                  <Stack alignItems="flex-end">
                    <Tooltip title={risk.risk_label}>
                      <IconButton onClick={() => handleOpen(risk.risk_label)}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="center" columnGap={1}>
                    <Typography variant="h3">{risk.risk_label}</Typography>
                  </Stack>
                  <div>
                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                      <Typography variant="h1" sx={price}>
                        {currencyFormatter(risk.price, { code: 'EUR' })}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        {t('signup.step9.month')}
                      </Typography>
                    </div>
                    {selected.includes(risk.id) ? (
                      <Button
                        onClick={() => handleSelect(risk.id)}
                        style={{ width: '100%' }}
                        variant="contained"
                        color="primary"
                        startIcon={<TickCircle />}
                      >
                        {t('signup.step9.selected')}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleSelect(risk.id)}
                        style={{  width: '100%',
                          border: '1px solid',
                          borderColor: 'grey.500',}}
                        variant="contained"
                        color="grey"
                        startIcon={<RemoveCircle />}
                      >
                        {t('signup.step9.unselected')}
                      </Button>
                    )}
                  </div>
                  <div style={{ textAlign: 'left', marginTop: 50 }}>{risk.description}</div>
                </MainCard>
              </Grid>
            ))}
            {explanation && (
              <Dialog onClose={handleClose} open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                  {explanation.label}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  <Typography gutterBottom>{explanation.content}</Typography>
                </DialogContent>
              </Dialog>
            )}
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <MainCard border={true} sx={{ height: '100%' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                    <Typography variant="h5">{t('signup.step9.maxCompensation')}</Typography>
                    <SliderContainer>
                      <CustomSlider />
                    </SliderContainer>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                    {selected.length >= 3 ? (
                      <>
                        <Typography variant="h5">{t('signup.step9.numClaims')}</Typography>
                        <SliderContainer>
                          <Slider
                            value={sliderValue2}
                            disabled={selected.length < risks.length}
                            min={3}
                            max={5}
                            step={2}
                            marks={[
                              { value: 3, label: '3' },
                              { value: 5, label: '5' },
                            ]}
                            onChange={(e, value) => setSliderValue2(value)}
                          />
                        </SliderContainer>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
                    <Typography variant="h5">{t('signup.step9.code')}</Typography>
                    <InputComponent
                      customStyles={{
                        width: '210px',
                        height: '40px',
                        borderRadius: '4px',
                        marginTop: '15px',
                      }}
                      label={t('signup.step9.promoCode')}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <MainCard border={true} sx={{ width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Link
                  sx={{
                    typography: { xs: 'h5' },
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  color="grey"
                >
                  {t('termsAndCondition')}
                </Link>
              </Grid>
              <Grid item xs={12} md={6} alignItems="flex-end">
                <Stack
                  alignItems="flex-end"
                  justifyContent="space-between"
                  sx={{ flexDirection: { xs: 'row', md: 'column' } }}
                >
                  <Typography color="text.secondary" sx={{ typography: { xs: 'h4', md: 'h5' }, textAlign: { md: 'right' } }}>
                    {t('signup.step9.total')}
                  </Typography>
                  <Typography variant="h1" sx={price}>
                    {currencyFormatter(calculateTotal(), { code: 'EUR' })}
                  </Typography>
                  <Typography color="text.secondary" sx={{ typography: { xs: 'h4', md: 'h5' }, textAlign: { md: 'right' } }}>
                    {t('signup.step9.TTC')}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </MainCard>
        </ListItem>
        <ListItem>
          <Grid container spacing={3} sx={{ textAlign: 'right' }}>
            <Grid item xs={12} md={12}>
              <Button
                disabled={selected.length === 0}
                variant="contained"
                color="primary"
                sx={{ width: { xs: '100%', md: 250 } }}
                onClick={handleNextClick}
              >
                {t('buttons.next')}
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </>
  );
};

export default Step9;
