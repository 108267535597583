// material-ui
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project-imports
import AuthWrapper from 'sections/auth/AuthWrapper';
import AuthResetPassword from 'sections/auth/auth-forms/AuthResetPassword';
import AuthDivider from 'sections/auth/AuthDivider';
import LanguageSwitcher from 'components/LanguageSwitcher';
// ================================|| RESET PASSWORD ||================================ //

export default function ResetPassword() {
  return (
    <AuthWrapper>
      <Grid container spacing={3}>        
        <Grid item xs={12}>
          <Stack sx={{ mb: { xs: -0.5, sm: 0.5 } }} spacing={1}>
            <Typography variant="h3">Reset Password</Typography>
            <Typography color="secondary">Please choose your new password</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthResetPassword />
        </Grid>
        <Grid item xs={12}>
          <AuthDivider/>
        </Grid>
        <Grid item xs={12} >
          <div style={{textAlign:'center'}}>
            <LanguageSwitcher/>
          </div>
        </Grid>     
      </Grid>
    </AuthWrapper>
  );
}
