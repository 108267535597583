import React from "react";
import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";

const FeatureCard = ({ number, icon, title, description, isAnimation = false, isMobile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: isMobile ? 200 : 250,
        margin: "0 auto",
        marginBottom: isMobile ? "15px" : "0px",
        textAlign: "center",
        padding: isMobile ? "10px" : "20px",
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          borderRadius: "50%",
          backgroundColor: "#F066B5",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: 1,
        }}
      >
        {number}
      </Box>

      <Box
        sx={{
          width: isMobile ? 80 : 100,
          height: isMobile ? 80 : 100,
        }}
      >
        {isAnimation ? (
          <Lottie animationData={icon} loop={true} style={{ width: "100%", height: "100%" }} />
        ) : (
          <img src={icon} alt={title} style={{ width: "100%", height: "100%" }} />
        )}
      </Box>

      <Typography
        variant={isMobile ? "h5" : "h6"}
        sx={{
          fontWeight: "bold",
          marginBottom: 1,
          color: "#282828",
          width: "100%",
        }}
      >
        {title}
      </Typography>

      <Typography
        variant={isMobile ? "body2" : "body1"}
        sx={{
          color: "#666",
          width: "100%",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default FeatureCard;
