import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// material-ui
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

// assets
import { CardCoin, Lock, Profile } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

function getPathIndex(pathname) {
  let selectedTab = 0;
  switch (pathname) {
    case '/my-profiles/payment':
      selectedTab = 1;
      break;
    case '/my-profiles/password':
      selectedTab = 2;
      break;
    // case '/my-profiles/settings':
    //   selectedTab = 3;
    //   break;
    case '/my-profiles':
    default:
      selectedTab = 0;
  }
  return selectedTab;
}

// ==============================|| USER PROFILE - BASIC ||============================== //

export default function ProfileTab({ handleClose }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(getPathIndex(pathname));
  const handleListItemClick = (index, route) => {
    setSelectedIndex(index);
    navigate(route);
  };

  useEffect(() => {
    setSelectedIndex(getPathIndex(pathname));
  }, [pathname]);

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: 'secondary.main' } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={() => handleListItemClick(0, '/my-profiles')}>
        <ListItemIcon>
          <Profile size={18} />
        </ListItemIcon>
        <ListItemText primary={t('profile.tabs.personalInformation')} />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={() => handleListItemClick(1, '/my-profiles/payment')}>
        <ListItemIcon>
          <CardCoin size={18} />
        </ListItemIcon>
        <ListItemText primary={t('profile.tabs.payment')} />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={() => handleListItemClick(2, '/my-profiles/password')}>
        <ListItemIcon>
          <Lock size={18} />
        </ListItemIcon>
        <ListItemText primary={t('profile.tabs.changePassword')} />
      </ListItemButton>
      {/*<ListItemButton selected={selectedIndex === 3} onClick={() => handleListItemClick(3, '/my-profiles/settings')}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <Setting3 size={18} />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary={t('profiles.tabs.settings')} />*/}
      {/*</ListItemButton>*/}
    </List>
  );
}
