import { createBrowserRouter } from 'react-router-dom';

// project import
import MainRoutes from './ClientRoutes';
import LoginRoutes from './LoginRoutes';
import GuestRoutes from './GuestRoutes';
import OtherRoutes from './OtherRoutes';
// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter([GuestRoutes, LoginRoutes, MainRoutes, OtherRoutes]);

export default router;
