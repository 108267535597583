import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

export const useExplanation = ({step, clientType}) => {
  const { t } = useTranslation(); 
  const [explanation, setExplanation] = useState({question: t('signup.questions.clientType.question'), answer: t('signup.questions.clientType.answer')})

  useEffect(() => {
    switch (step) {
      case 1:
        setExplanation({question: t('signup.questions.clientInfo.question'), answer: t('signup.questions.clientInfo.answer')})
        break
      case 2:
        setExplanation({question: t('signup.questions.birthday.question'), answer: t('signup.questions.birthday.answer')})

        break
      case 3:
        setExplanation({question: t('signup.questions.phoneNumber.question'), answer: t('signup.questions.phoneNumber.answer')})

        break
      case 4:
        setExplanation({question: t('signup.questions.phoneNumber.question'), answer: t('signup.questions.phoneNumber.answer')})

        break
      case 5:
        setExplanation({question: t('signup.questions.email.question'), answer: t('signup.questions.email.answer')})

        break
      case 6:
        clientType === "PERSONAL" ? (
          setExplanation({question: t('signup.questions.country.question'), answer: t('signup.questions.country.answer')})
        ) : (
          setExplanation({question: t('signup.questions.company.question'), answer: t('signup.questions.company.answer')})
        );
        break
      case 7:
        clientType === "PERSONAL" ? (
          setExplanation({question: t('signup.questions.people.question'), answer: t('signup.questions.people.answer')})
        ) : (
          setExplanation({question: t('signup.questions.employees.question'), answer: t('signup.questions.employees.answer')})
        );
       
        break
      
    }
  }, [step, t, clientType])
  return explanation
}