import React from "react";
import { Box, Paper, Typography, useTheme, Tooltip } from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/base";



const CardComponent = ({ active, icon, text, onClick, isAnimation = false }) => {
  const theme = useTheme()
  const { t } = useTranslation();

  return (
    <Tooltip title={t("homePage.requestQuote")} arrow
    followCursor
    sx={{
      backgroundColor: "#F066B5",
      color: "#ffffff",
      fontSize: "14px",
      borderRadius: "4px",
      padding: "8px",
      "& .MuiTooltip-arrow": {
        color: "#F066B5",
      },}}>
      <Paper
        elevation={3}
        sx={{
          width: 130,
          height: 130,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 2,
          border: "1px solid #e0e0e0",
          padding: 1,
          backgroundColor: "transparent",
          cursor: "pointer",
          '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.2s ease-in-out',
          }
        }}
      >
        <Button style={{border: 'none', backgroundColor: 'transparent', cursor: 'pointer'}} onClick={onClick}>

          <Box sx={{ width: 100, height: 100 }}>
            {isAnimation ? (
              <Lottie animationData={icon} loop={true} />
            ) : (
              <img src={icon} alt={text} style={{ marginTop:"15px", marginBottom:"5px",width: "80%", height: "80%" }} />
            )}
          </Box>
          <Typography variant="h6" sx={{  marginBottom: 2, fontWeight: "bold" }}>
            {text}
          </Typography>
        </Button>

      </Paper>
    </Tooltip>
  );
};

export default CardComponent;
