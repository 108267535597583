import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, isValid, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from 'components/@extended/IconButton';
import TypeSwitcher from 'components/frontOffice/HomePageComponents/TypeSwitcher';
import Constants from "common/Constants";

const BirthdayStep = ({ nextStep, prevStep }) => {
  const { t } = useTranslation();
  const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};

  const [date, setDate] = useState(null);
  const [selection, setSelection] = useState(clientObject.client_type || Constants.CLIENT_TYPE.PERSONAL);
  const [error, setError] = useState("");

  useEffect(() => {
    if (clientObject.date_of_birth) {
      const parsedDate = parse(clientObject.date_of_birth, 'dd/MM/yyyy', new Date());
      setDate(parsedDate);
    }
  }, []);

  const toggleSelection = () => {
    const newSelection = selection === Constants.CLIENT_TYPE.PERSONAL ? Constants.CLIENT_TYPE.PROFESSIONAL : Constants.CLIENT_TYPE.PERSONAL;
    setSelection(newSelection);
    const updatedClientObject = { ...clientObject, client_type: newSelection };
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(updatedClientObject));
  };

  const handleDateChange = (value) => {
    if (isValid(value)) {
      const today = new Date();
      const minDate = new Date(today.getFullYear() - 70, today.getMonth(), today.getDate());
      const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

      if (value < minDate || value > maxDate) {
        setError(t('signup.dateOfBirth.errorAgeRestriction'));
        setDate(null);
      } else {
        setError("");
        setDate(value);
      }
    } else {
      setError(t('signup.dateOfBirth.errorInvalid'));
      setDate(null);
    }
  };

  const handleNextClick = () => {
    const formattedDate = format(date, 'dd/MM/yyyy');
    const updatedClientObject = { ...clientObject, date_of_birth: formattedDate };
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(updatedClientObject));
    nextStep({ date_of_birth: formattedDate });
  };

  const handleBackClick = () => {
    prevStep();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ position: 'relative', width: '100%', maxWidth: '800px' }}>
        <IconButton 
          onClick={handleBackClick} 
          style={{ position: 'absolute', left: 0, top: "60%" }}
        >
          <ArrowBackIcon style={{ width: 30, height: 30 }} />
        </IconButton>
        <Box sx={{ textAlign: 'center', marginLeft: '46px' }}>
          <TypeSwitcher selection={selection} toggleSelection={toggleSelection} homePage={true} />
          <Typography variant="h2" gutterBottom>
            {t('signup.dateOfBirth.title1')}
          </Typography>
          <Typography variant="h2" gutterBottom>
            {t('signup.dateOfBirth.title')}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <DatePicker
              maxDate={new Date()}
              label={t('signup.dateOfBirth.label')}
              value={date}
              onChange={handleDateChange}
              format="dd/MM/yyyy"
            />
          </LocalizationProvider>
          {error && (
            <Typography variant="body2" color="error" marginTop="10px">
              {error}
            </Typography>
          )}
          <Stack direction='row' alignItems='center' justifyContent='center' columnGap={2} mt={4}>
            <Button 
              size='large' 
              disabled={!date} 
              variant="contained" 
              color="primary" 
              style={{ width: 250 }} 
              onClick={handleNextClick}
            >
              {t('buttons.next')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default BirthdayStep;
