import React from 'react';
import PropTypes from 'prop-types';
import styles from './DateTimePickerComponent.module.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Constants from '../../../common/Constants';


const DateTimePickerComponent = ({type, label, value, onChange, noHelperText, width, disableFuture}) => {

  function renderDatePicker() {
    return (
      <DatePicker
        value={value}
        onChange={onChange}
        label={label}
        format={Constants.DATE_FORMAT}
        disableFuture={disableFuture}
        sx={{
          width: width
        }}
        slotProps={{
          textField: {
            helperText: noHelperText ? '' : Constants.DATE_FORMAT,
          },
        }}
        // slotProps={{ textField: { placeholder: label } }}
      />      
    )
  }

  function renderComponent(type) {
    var DT_TYPE_CONST = Constants.DATETIME_PICKER.TYPE;
    switch(type){
      case DT_TYPE_CONST.DATE_PICKER:
      default:
        return renderDatePicker();
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        {renderComponent()}
    </LocalizationProvider>
  )
};

DateTimePickerComponent.propTypes = {};

DateTimePickerComponent.defaultProps = {};

export default DateTimePickerComponent;
