import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Chip, Grid, List, ListItem, Stack, Typography } from '@mui/material';

// Project imports
import MainCard from 'components/MainCard';
import FileUploaded from 'components/FileUploaded';
import { fetcher } from 'utils/axios';
import { COMPLAINT } from 'common/Constants';

// Utilities
import dateFormat from 'dateformat';
import { format as currencyFormatter } from 'currency-formatter';
import { useTranslation } from 'react-i18next';

export default function ExpandComplaintDetail({ data }) {
    const { t } = useTranslation(); // Translation hook
    const [policyDetail, setPolicyDetail] = useState(null);
    const [claimDetail, setClaimDetail] = useState(null);

    const {
        policy_id,
        claim_id,
        complaint_reference_number,
        financial_compensation_amount,
        created_at,
        receipt_date,
        complaint_type_id,
        complaint_level_id,
        documents,
        status_id
    } = data;

    const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (policy_id) {
                    const policyData = await fetcher(`/policies/detail/${policy_id}`);
                    setPolicyDetail(policyData);
                }

                if (claim_id) {
                    const claimData = await fetcher(`/claims/detail/${claim_id}`);
                    setClaimDetail(claimData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [policy_id, claim_id]);

    const STATUS_MAP = {
        [COMPLAINT.STATUS.DRAFT]: { color: 'default', label: t('complaint.status.draft') },
        [COMPLAINT.STATUS.DECLARED]: { color: 'primary', label: t('complaint.status.declared') },
        [COMPLAINT.STATUS.RECEIPT]: { color: 'error', label: t('complaint.status.receipt') },
        [COMPLAINT.STATUS.OPEN]: { color: 'success', label: t('complaint.status.open') },
        [COMPLAINT.STATUS.PENDING]: { color: 'warning', label: t('complaint.status.pending') },
        [COMPLAINT.STATUS.CLOSED]: { color: 'info', label: t('complaint.status.closed') },
    };

    const renderStatus = (status) => {
        const statusInfo = STATUS_MAP[status] || { color: 'default', label: status };
        return <Chip color={statusInfo.color} label={statusInfo.label} size="default" variant="light" />;
    };

    const renderComplaintButton = (status) => {
        const buttonMap = {
            [COMPLAINT.STATUS.DRAFT]: { color: 'secondary', label: t('complaint.button.openNewComplaint') },
            [COMPLAINT.STATUS.CLOSED]: { color: 'secondary', label: t('complaint.button.openNewComplaint') },
            [COMPLAINT.STATUS.OPEN]: { color: 'error', label: t('complaint.button.cancelComplaint') },
            [COMPLAINT.STATUS.RECEIPT]: { color: 'error', label: t('complaint.button.cancelComplaint') },
            [COMPLAINT.STATUS.DECLARED]: { color: 'error', label: t('complaint.button.cancelComplaint') },
            [COMPLAINT.STATUS.PENDING]: { color: 'error', label: t('complaint.button.cancelComplaint') },
        };
        const { color, label } = buttonMap[status] || { color: 'secondary', label: t('complaint.button.secondary') };

        return <Button variant="contained" color={color} onClick={underConstruction}>{label}</Button>;
    };

    const underConstruction = () => {
        alert(t('complaint.underConstruction'));
    };

    return (
        <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }}>
            <Grid item xs={12} sm={7} md={8} xl={8.5}>
                <Stack spacing={2.5}>
                    <MainCard title={t('complaint.details.title')}>
                        <List sx={{ py: 0 }}>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.number')}</Typography>
                                            <Typography variant="h3">{complaint_reference_number}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.financialCompensation')}</Typography>
                                            <Typography variant="h3">
                                                { financial_compensation_amount ? currencyFormatter(financial_compensation_amount, { code: 'EUR' }) : ''}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.createdDate')}</Typography>
                                            <Typography>{dateFormat(created_at, 'yyyy-mm-dd HH:MM')}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.receiptDate')}</Typography>
                                            <Typography>{receipt_date ? dateFormat(receipt_date, 'yyyy-mm-dd HH:MM') : ''}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.title')}</Typography>
                                            <Typography>{data.title}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.type')}</Typography>
                                            <Typography>
                                                <Chip color="primary" label={t(`complaint.type.${complaint_type_id}`)} size="Default" variant="light" />
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.status')}</Typography>
                                            <Typography>{renderStatus(status_id)}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {complaint_type_id === 'Policy' && policy_id && policyDetail && (
                                            <Stack spacing={0.5}>
                                                <Typography color="secondary">{t('complaint.details.policyNumber')}</Typography>
                                                <Typography>{policyDetail.amendment_number}</Typography>
                                                {/* Add more details as needed */}
                                            </Stack>
                                        )}
                                        {complaint_type_id === 'Claim' && claim_id && claimDetail && (
                                            <Stack spacing={0.5}>
                                                <Typography color="secondary">{t('complaint.details.claimReferenceNumber')}</Typography>
                                                <Typography>{claimDetail.claim_reference_number}</Typography>
                                                {/* Add more details as needed */}
                                            </Stack>
                                        )}
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.description')}</Typography>
                                            <Typography>{data.description}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.evidenceDocuments')}</Typography>
                                            {documents.length > 0 && documents.map((document) => (
                                                <FileUploaded file={document} key={document.id} />
                                            ))}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('complaint.details.level')}</Typography>
                                            <Typography>
                                                { complaint_level_id ? <Chip color="error" label={t(`complaint.level.${complaint_level_id}`)} size="default" variant="light" /> : '' }
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem divider={!matchDownMD}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <Stack spacing={0.5}>
                                            {renderComplaintButton(status_id)}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    </MainCard>
                </Stack>
            </Grid>
        </Grid>
    );
}

ExpandComplaintDetail.propTypes = { data: PropTypes.object.isRequired };
