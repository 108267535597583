import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project-imports
import MainCard from 'components/MainCard';
// assets
import { Lock } from 'iconsax-react';
import coming from 'assets/images/maintenance/img-soon-2.svg';
import React from 'react';
import LogoMain from 'components/logo/LogoMain';

// ==============================|| COMING SOON ||============================== //

function TimerBox({ count }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainCard content={false} sx={{ width: { xs: 60, sm: 80 } }}>
      <Stack justifyContent="center" alignItems="center">
        <Box sx={{ py: 1.75 }}>
          <Typography variant={matchDownSM ? 'h4' : 'h2'}>{count}</Typography>
        </Box>
      </Stack>
    </MainCard>
  );
}

export default function ComingSoon() {

  const [password, setPassword] = React.useState('');

  const handleUnlock = (e) => {
    debugger;
    if (password === 'FR_TN_VT_2024') {
      sessionStorage.setItem('AVEKTOI_BETA_ACCESSS', true);
      window.location.href = '/';
    }
    else {
      alert('Invalid password');
    }
  }

  return (
    <>    
      <Container fixed>       
        <Grid container spacing={4} alignItems="center" justifyContent="center" sx={{ minHeight: '100vh', py: 2 }}>
          <Grid item md={6}>
            <Box sx={{ height: { xs: 310, sm: 420 }, width: { xs: 360, sm: 'auto' } }}>
              <img src={coming} alt="coming soon 1" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={3} direction="column" alignItems="center">
              <Grid item xs={12}>
                <Stack spacing={1} justifyContent="center" alignItems="center">
                <LogoMain/>
                  <Typography align="center" variant="h1">
                    Coming Soon
                  </Typography>
                  <Typography align="center" color="text.secondary">
                    Insurance Solution for you
                  </Typography>
                </Stack>
              </Grid>
              {/* <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={{ xs: 1, sm: 2 }}>
                  <TimerBox count={days} />
                  <TimerBox count={hours} />
                  <TimerBox count={minutes} />
                  <TimerBox count={seconds} />
                </Stack>
              </Grid> */}
              <Grid item xs={12} sx={{ width: { xs: 380, md: 380, lg: 380 } }}>
                <Stack spacing={3} sx={{ mt: 2 }}>
                  <Stack direction="row" spacing={1}>
                    <TextField type="password" fullWidth placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <Button variant="contained" sx={{ width: '50%' }} startIcon={<Lock variant="Bold" />} onClick={handleUnlock}>
                      Unlock
                    </Button>
                  </Stack>
                  {/* <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                    <IconButton shape="rounded" color="secondary">
                      <Facebook variant="Bulk" size={20} />
                    </IconButton>
                    <IconButton shape="rounded" color="secondary">
                      <Google variant="Bulk" size={20} />
                    </IconButton>
                  </Stack> */}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

TimerBox.propTypes = { count: PropTypes.number };
