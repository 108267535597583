import { Outlet } from 'react-router-dom';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import LockGuard from 'utils/route-guard/LockGuard';

// ==============================|| LAYOUT - AUTH ||============================== //

export default function GuestLayout() {
  return (
    <LockGuard>
      <Outlet />
    </LockGuard>
  );
}
