import React, { useEffect } from 'react';
import './App.css';
import './common/styles.css';
import { setDefaultLanguage } from './utils/languageSetup';
import Constants from './common/Constants';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import Navbar from "components/NavbarComponent"
// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
function App() {
  useEffect(() => {
    setDefaultLanguage();
  }, []);

  localStorage.setItem(Constants.ACCESS_API_KEY, Constants.ACCESS_API_KEY_SAMPLE); // Partner access API key, to be setup later

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              {/* <Navbar /> */}
              <RouterProvider router={router} />
              <Snackbar />
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
}

export default App;
