// material-ui
import { useTheme } from '@mui/material/styles';
import { height } from '@mui/system';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

export default function LogoMain({height , width}){
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
    <>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 2048 533" width={width? width:"200"} height={height? height :"80px"}>
        <path transform="translate(1153,6)" d="m0 0h17l11 4 6 4 5 4 7 11 2 8v10l-4 16-11 28-18 44-15 37-22 54-21 52-14 34 1 1 21-16 14-10 16-12 19-14 18-13 15-9 13-5 5-1h14l12 4 10 9 5 7 3 11v9l-3 12-6 11-9 10-9 8-18 13-19 14-18 13-10 7 2 5 14 22 11 17 14 22 11 17 11 18 4 9 1 4v13l-4 11-6 9-8 8-10 6-8 3-6 1h-10l-10-4-8-7-8-10-24-36-29-43-8-12-27-40-2 2-15 37-20 49-11 26-9 14-9 10-8 6-10 5-13 3h-12l-13-4-9-6-7-9-3-8v-16l4-14 42-103 17-42 22-54 15-37 20-49 17-42 20-49 17-42 8-16 6-9 10-10 10-6 9-3z" fill={theme.palette.primary.main} />
        <path transform="translate(162,227)" d="m0 0h22l17 3 14 5 10 6 10 9 9 10 11-12 8-7 13-6 9-2h16l10 3 9 6 8 9 4 10v17l-5 17-16 39-20 50-20 49-3 7h51l16 3 12 6 6 7 3 6 1 5v13l-4 13-6 9-7 7-16 8-12 3-7 1h-70l-12-3-9-5-7-6-7-10-13 10-14 8-10 4-10 2h-62l-16-3-15-6-12-8-12-11-9-12-8-15-5-15-3-15-1-8v-29l3-22 5-20 7-19 8-16 9-15 12-16 12-14 11-11 11-9 14-10 17-9 16-6 19-4zm16 76-13 2-12 5-11 8-12 11-12 15-10 17-6 16-3 17v15l2 11 5 10 7 6 11 4h24l12-3 9-5 8-8 8-17 15-37 11-28 2-8v-9l-3-7-6-7-12-6-12-2z" fill={theme.palette.primary.main} />
        <path transform="translate(786,227)" d="m0 0h24l21 3 17 5 16 8 13 9 11 11 7 10 7 14 4 15 1 6v26l-3 15-5 13-7 12-11 12-12 9-16 8-14 4-19 3-20 1h-79l3 11 6 9 10 5 7 2 8 1 131 1 16 3 12 6 6 7 3 7 1 5v12l-3 11-4 8-7 8-11 7-14 5-13 2h-136l-21-3-15-4-15-6-12-7-10-8-9-9-9-14-5-10-5-15-3-16-1-9v-29l3-23 6-23 9-21 10-18 12-16 11-12 14-14 16-12 16-9 15-7 21-6zm7 76-14 2-12 5-9 7-8 8-10 15v2h56l16-4 7-6 1-2v-11l-4-8-6-5-6-2z" fill={theme.palette.primary.main} />
        <path transform="translate(1668,227)" d="m0 0h24l21 3 16 5 14 6 11 7 14 11 8 8 10 13 9 16 6 17 3 13 1 8v34l-3 19-6 22-8 20-12 23-12 17-9 10-7 8-11 9-15 10-15 7-19 5-26 3h-47l-27-3-17-4-16-8-10-8-8-7-9-11-9-14-7-17-4-15-2-14v-34l3-20 6-21 9-21 11-18 10-13 9-10 4-5 8-7 9-8 14-10 16-9 18-8 17-5 17-3zm-3 81-16 4-12 7-10 9-9 9-10 15-6 13-4 14-1 6v21l4 13 6 9 10 7 13 4 7 1h17l15-3 12-6 12-11 9-13 8-16 4-13 2-14v-14l-3-14-6-11-9-9-12-6-10-2z" fill={theme.palette.primary.main} />
        <path transform="translate(1436,131)" d="m0 0h20l10 4 9 7 7 10 2 6v18l-4 15-11 25-8 17h23l15 3 10 5 7 7 4 10v18l-4 11-6 9-8 7-12 6-15 4-8 1-38 1-35 87-15 37 52 1 16 3 10 5 7 7 4 9 1 11-2 13-6 12-9 9-16 8-12 3-7 1h-92l-15-3-12-5-9-7-7-7-5-10-2-7v-20l5-19 14-33 25-62 14-34h-16l-13-3-10-6-6-8-3-10v-11l3-13 6-10 8-8 16-8 12-3 7-1h30l1-5 23-57 8-15 10-12 10-7 12-5z" fill={theme.palette.primary.main} />
        <path transform="translate(604,227)" d="m0 0h13l11 3 10 6 7 8 5 12 1 14-3 12-8 16-7 11-14 18-10 13-28 36-10 13-14 18-10 13-14 18-10 13-14 18-10 13-11 14-9 10-10 9-16 8-14 3h-13l-14-3-10-5-10-9-7-9-6-13-3-13-1-8v-21l14-153 3-17 6-16 8-11 9-8 8-4 11-3h17l13 4 9 8 4 8 2 12-1 19-10 96v6l28-38 13-18 42-57 11-16 8-10 8-8 12-8 9-3z" fill={theme.palette.primary.main} />
        <path transform="translate(1947,233)" d="m0 0h16l12 4 8 6 8 9 3 7 1 5v9l-4 17-9 24-20 49-12 30-19 47h52l16 3 12 6 6 7 3 7 1 5v11l-3 12-4 8-7 8-11 7-14 5-13 2h-86l-17-3-13-5-10-6-10-10-6-12-2-11 1-13 4-14 11-28 18-44 42-103 7-13 8-10 8-7 14-7z" fill={theme.palette.primary.main} />
        <path transform="translate(1972,113)" d="m0 0h17l13 4 9 5 10 9 7 11 4 11 1 5v14l-3 12-5 10-7 9-11 8-8 4-16 3-14-1-14-5-9-7-5-5-6-7-5-11-2-9v-16l3-11 5-10 11-12 11-7 9-3z" fill={theme.palette.primary.main} />
      </svg>

    </>
  );
}
