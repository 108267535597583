import serviceLinks from 'common/Links.json'
import Headers from 'common/types/Headers';
import { fetcher, fetcherPost } from 'utils/axios';

const headers = new Headers();

class ComplaintService {
  static async getComplaintListByClientId(clientId) {
    if (clientId) {
      const url = `${serviceLinks['Complaints']['list-by-client'].url.replace('{client_id}', clientId)}`;
      try {
        return await fetcher([url, {method: 'GET', headers: headers.GetHeaders()}]);
      } catch (err) {
        throw err;
      }
    } else {
      throw new Error('Client ID is required');
    }
  }

  static async getMyComplaintList() {
    const url = serviceLinks['Complaints']['my-complaints'].url;
    try {
      return await fetcher([url, {method: 'GET', headers: headers.GetHeaders()}]);
    } catch (err) {
      throw err;
    }
  }

  static async getComplaintDetailByComplaintId(complaintId) {
    if (complaintId) {
      const url = `${serviceLinks['Complaints']['detail'].url.replace('{complaint_id}', complaintId)}`;
      try {
        return await fetcher([url, {method: 'GET', headers: headers.GetHeaders()}]);
      } catch (err) {
        throw err;
      }
    } else {
      throw new Error('Complaint ID is required');
    }
  }

  static async createNewComplaint(params) {
    if (params) {
      const url = serviceLinks['Complaints']['create'].url;
      try {
        return await fetcherPost([url, params] );
      } catch (err) {
        throw err;
      }
    } else {
      throw new Error('Params are required');
    }
  }
}

export default ComplaintService;