import React, { useState } from "react";
import SignupLayout from "layouts/frontOffice/StyledSignupLayout";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import TextsmsIcon from '@mui/icons-material/Textsms';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import InputComponent from "components/InputComponent";
import Constants from "common/Constants";
import { fetcherPost } from "utils/axios";
import { Instructions } from "./Register/VerifyPhoneStep";
import { useNavigate } from "react-router";

const CreatePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleNextStep = async () => {
    const clientObject = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));
    try {
      await fetcherPost(['clients/create-password', {email: clientObject.email, newPassword: password}])
      alert(t('signup.confirmPassword.createSuccessfully'))
      navigate('/login')
    } catch (ex) {
      console.error(ex)
    }
  }

  const handleConfirmPassword = (event) => {
    const value = event.target.value
    if(password !== value) {
      setError(t('signup.confirmPassword.passwordNotMatch'))
    } else {
      setError('')
    }
    setConfirmPassword(value)
  }

  return (
    <SignupLayout step={1}>
      <div style={{ marginTop: 30}}>
        <Typography variant="h2">{t('signup.step5.title1')}</Typography>
        <Typography variant="h2">{t('signup.step5.title2')}</Typography>
        <div style={{marginTop: 30, maxWidth: 600}}>
          <div style={{ maxWidth: 350 }}>
            <InputComponent
              label={t('signup.step5.newPassword')}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputComponent
              label={t('signup.step5.confirmPassword')}
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPassword}
              error={!!error}
              helperText={error}
            />
            <Stack direction='row' alignItems='center' justifyContent='center' columnGap={2}>
              <Button 
                disabled={!(password && confirmPassword)} 
                variant="contained" 
                color="primary" 
                style={{ width: 250 }} 
                onClick={handleNextStep}
              >
                {t('buttons.submit')}
              </Button>
            </Stack>
          </div>
          <Instructions>
            {t('signup.step3.haventReceivedCode')} <span className="resend-link" onClick={() => {}}>{t('signup.step3.resendCode')}</span>
          </Instructions>
        </div>
      </div>
      <Stack position='absolute' bottom={16} left={16} right={16} direction='row' alignItems="center" justifyContent='space-between'>
        <Tooltip title={t('signup.questions.email.question')}>
          <IconButton size="large" onClick={handleClickOpen}>
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {t('signup.questions.email.question')}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom>
              {t('signup.questions.email.answer')}
            </Typography>
          </DialogContent>
        </Dialog>
        <IconButton size="large" color="primary">
          <TextsmsIcon />
        </IconButton>
      </Stack>
    </SignupLayout>
  );
};

export default CreatePassword;
