
import Constants from 'common/Constants';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';





const SwitchContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }));
  
  const SwitchButton = styled('div')(({ isSelected , homePage}) =>({
    width: homePage? '60px':'75px',
    height: homePage? '20px':'28px',
    backgroundColor: '#F066B5',
    borderRadius: '20px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: homePage? '0 0px':'0 15px', 
    '@media (max-width: 600px)': {
      margin: '10px 0', 
    },
  }));
  
  const CircleButton = styled('div')(({ isSelected , homePage}) => ({
  width: homePage? '28px': '35px',
  height: homePage? '28px':'35px',
  borderRadius: '20px',
  backgroundColor: '#EB3D9F',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: isSelected === Constants.CLIENT_TYPE.PERSONAL ? '0px' : 'calc(100% - 20px)',
  transition: 'left 0.3s',
  color: '#ffffff',
  }));
  
  const OptionLabel = styled('div')(({ isSelected, theme,homePage }) => ({
  fontSize: homePage?  '23px':'30px',
  lineHeight: '14px',
  letterSpacing: '0.5px',
  color: isSelected ? '#EB3D9F' : '#6B7280',
  fontFamily: 'Itim, sans-serif',
  margin: '0 30px',
  [theme.breakpoints.down('sm')]: {
    margin: '10px 0',
  },
  }));

const TypeSwitcher  =( {toggleSelection , selection, homePage}) =>{
  const { t } = useTranslation();


  return(
<SwitchContainer>
          <OptionLabel isSelected={selection === Constants.CLIENT_TYPE.PERSONAL} homePage={homePage}>
          {t('homePage.typeSwitcher.personal')}
          </OptionLabel>
          <SwitchButton  homePage={homePage} onClick={toggleSelection}>
            <CircleButton homePage={homePage} isSelected={selection } >
              {selection === Constants.CLIENT_TYPE.PERSONAL ? <HomeIcon /> : <BusinessIcon />}
            </CircleButton>
          </SwitchButton>
          <OptionLabel isSelected={selection === Constants.CLIENT_TYPE.PROFESSIONAL} homePage={homePage}>
          {t('homePage.typeSwitcher.professional')}
          </OptionLabel>
        </SwitchContainer>
  )
}

export default TypeSwitcher