import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Material-UI components
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

// Icons
import { Card, Logout, Profile } from 'iconsax-react';

const ProfileTab = ({ handleLogout, handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const routeMap = {
    '/my-profiles': 0,
    '/my-profiles/payment': 1,
  };

  useEffect(() => {
    // Update selected index based on the current route
    const currentIndex = routeMap[location.pathname] ?? null;
    setSelectedIndex(currentIndex);
  }, [location.pathname]);

  const handleListItemClick = (index, path) => {
    if (index !== selectedIndex) {
      setSelectedIndex(index);
      if (path) navigate(path);
    }
    handleClose();
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': { minWidth: 32 },
      }}
    >
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={() => handleListItemClick(0, '/my-profiles')}
      >
        <ListItemIcon>
          <Profile variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={t('dashboard.view_profile')} />
      </ListItemButton>

      <ListItemButton
        selected={selectedIndex === 1}
        onClick={() => handleListItemClick(1, '/my-profiles/payment')}
      >
        <ListItemIcon>
          <Card variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={t('dashboard.billing')} />
      </ListItemButton>

      <ListItemButton
        onClick={handleLogout}
      >
        <ListItemIcon>
          <Logout variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={t('dashboard.logout')} />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProfileTab;