
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateFr from '../languages/frontOffice/translateFr';
import translateEn from '../languages/frontOffice/translateEn';
import translatePt from '../languages/frontOffice/translatePt';
import translateDe from '../languages/frontOffice/translateDe';
import translateIt from '../languages/frontOffice/translateIt';
import translateEs from '../languages/frontOffice/translateEs';
import Cookies from 'js-cookie';

const savedLanguage = Cookies.get('lang') || 'en';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translateEn,
    },
    fr: {
      translation: translateFr,
    },
    pt: {
      translation: translatePt,
    },
    de: {
      translation: translateDe,
    },
    it: {
      translation: translateIt,
    },
    es: {
      translation: translateEs,
    },
  },
  lng: savedLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['cookie', 'navigator'],
    caches: ['cookie']
  }
});

export default i18n;