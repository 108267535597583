import React from "react";
import { Typography, Box } from "@mui/material";

const TitleSection = ({ 
  titleParts, 
  paragraph, 
  paragraphVisible = true,
  titleSizes = [], 
  titleColors = [] ,
  textAlign,
  fontSize,
  isMobile
}) => {
  return (
    <Box sx={{ textAlign: "center", marginBottom: 4 , paddingLeft:"5px" ,paddingRight:"5px" }}>
      <Typography variant={"h3"} component="div" sx={{ fontWeight: "bold",   margin: "0 auto",  
}}>
        {titleParts.map((part, index) => (
          <Typography
            key={index}
            component="span"
            sx={{
              color: titleColors[index] || "inherit",
              fontSize: titleSizes[index] || "inherit",
              display: "inline",
              fontWeight: "bold",
              textAlign: "center",
              lineHeight: 1.2,
            }}
          >
            {part}
            {index < titleParts.length - 1 && " "} 
          </Typography>
        ))}
      </Typography>

      {paragraphVisible && paragraph && (
        <Typography variant="body1" sx={{ textAlign: textAlign || "center", margin: "0 auto", color: "#888", marginTop: 2 ,width: isMobile ? "80%" : "60%", fontSize:fontSize || "16px"}} >
          {paragraph}
        </Typography>
      )}
    </Box>
  );
};

export default TitleSection;
