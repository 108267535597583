import React from 'react';
import { styled } from '@mui/system';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme, customStyles }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  margin: 'auto',
  backgroundColor: customStyles?.backgroundColor || '#F3F4F6',
  borderRadius: customStyles?.borderRadius || '4px',
  height: customStyles?.height || '56px',
  width: customStyles?.width || '300px',
  marginBottom: customStyles?.marginBottom || '10px',
  color: customStyles?.color || '#6B7280',
  fontSize: customStyles?.fontSize || '15px',
  fontFamily: customStyles?.fontFamily || 'Roboto, sans-serif',
  fontWeight: customStyles?.fontWeight || '500',
  lineHeight: customStyles?.lineHeight || '14px',
  letterSpacing: customStyles?.letterSpacing || '0.5px',
  marginTop: customStyles?.marginTop || '0px',

}));

const CustomCheckbox = styled(Checkbox)(({ theme, customStyles }) => ({
  color: customStyles?.uncheckedColor || '#6B7280',
  '&.Mui-checked': {
    color: customStyles?.checkedColor || '#EC4899',
  },
  '& .MuiSvgIcon-root': {
    fontSize: customStyles?.iconSize || '24px',
  },
}));

const ChoiceComponent = ({ value, label, checked, onChange, customStyles }) => {
  return (
    <StyledFormControlLabel
      control={<CustomCheckbox
        icon={<CheckBoxOutlineBlankIcon />}
        checkedIcon={<CheckBoxIcon />}
        checked={checked}
        onChange={onChange}
        value={value}
        customStyles={customStyles}
      />}
      label={label}
      customStyles={customStyles}
    />
  );
};

export default ChoiceComponent;
