import SignupPage from '../pages/frontOffice/UserOnboard/SignupPage';
import SignupChoicePage from 'pages/frontOffice/UserOnboard/SignUpChoicePage';
import HomePage from "../pages/frontOffice/HomePage"
import Error404 from 'pages/maintenance/error/404'
import RegisterPolicyProcess from 'pages/frontOffice/RegisterPolicy/RegisterPolicy';
import Quotation from 'pages/frontOffice/Quotation';
import GuestLayout from 'layouts/Guest';
import CreatePassword from 'pages/frontOffice/UserOnboard/CreatePassword';
// ==============================|| MAIN ROUTES ||============================== //

const GuestRoutes = {
  path: '/',
  children: [{
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/have-an-account',
        element: <SignupChoicePage />,
      },
      {
        path: 'sign-up',
        element: <SignupPage/>
      },
      {
        path: 'sign-up-more-information',
        element: <CreatePassword />
      },
      {
        path: 'register-policy',
        element: <RegisterPolicyProcess/>
      },
      {
        path: 'quotation/*',
        element: <Quotation/>
      },
    ]
  },
  { 
    path: '*',
    element: <Error404 />,
  }
]
};

export default GuestRoutes;
