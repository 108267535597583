import Constants from "../Constants"

export default class Headers {
    GetHeaders = () => {
        return {
            "accept": 'application/json',
            "access-auth-token": localStorage.getItem(Constants.ACCESS_AUTH_TOKEN), //localStorage.getItem(Constants.ACCESS_AUTH_TOKEN), // After login successfully, pass received token here
            "access-api-key": localStorage.getItem(Constants.ACCESS_API_KEY) // Partner token, to be setup later
        }
    }
}