import { useContext, useEffect, useRef } from 'react';
import { Outlet } from 'react-router';

// material-ui
import Grid from '@mui/material/Grid';

// project-imports
import ProfileTabs from 'sections/apps/profiles/user/ProfileTabs';
import JWTContext from 'contexts/JWTContext';
import Constants from 'common/Constants';

// ==============================|| PROFILE - USER ||============================== //

export default function MyProfiles() {
  const inputRef = useRef(null);

  const { getMyProfile } = useContext(JWTContext);
  const userProfile = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));

  useEffect(() => {
    // Call getMyProfile when the component mounts
      getMyProfile(userProfile?.id);
  }, [getMyProfile, userProfile?.id]);

  const focusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <ProfileTabs focusInput={focusInput} />
      </Grid>
      <Grid item xs={12} md={9}>
        <Outlet context={inputRef} />
      </Grid>
    </Grid>
  );
}
