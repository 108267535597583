import React from "react";
import { Grid, Stack, Box, Typography, Link, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoMain from "components/logo/LogoMain";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: "#555555", color: "#f0f0f0", p: {xs: 4, md: 7} }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <LogoMain sx={{ color: "#F066B5", mb: 2 }} />
            <Typography variant="body2" sx={{ width: "80%", textAlign: "center", marginTop: "10px" }}>
              {t("footer.description")}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="h4" sx={{ mb: 2, textAlign: {xs: 'center'} }}>
            {t("footer.explore")}
          </Typography>
          <Grid container sx={{textAlign: {xs: 'center'}}}>
            <Grid item xs={6} md={12}>
              <Link href="/about" color="inherit" variant="body2">
                {t("footer.about")}
              </Link>
            </Grid>
            <Grid item xs={6} md={12}>
              <Link href="/services" color="inherit" variant="body2">
                {t("footer.services")}
              </Link>
            </Grid>
            <Grid item xs={6} md={12}>
              <Link href="/join-us" color="inherit" variant="body2">
                {t("footer.joinUs")}
              </Link>
            </Grid>
            <Grid item xs={6} md={12}>
              <Link href="/data-policy" color="inherit" variant="body2">
                {t("footer.dataPolicy")}
              </Link>
            </Grid>
            <Grid item xs={6} md={12}>
              <Link href="/legal-notice" color="inherit" variant="body2">
                {t("footer.legalNotice")}
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} sx={{ textAlign: {xs: 'center'}}}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t("footer.contact")}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            66 AV. CHAMPS ELYSEES, 75008 PARIS
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            (+33) 744 752 443
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            contact@avektoi.com
          </Typography>

          <Typography variant="h4" sx={{ mb: 2 }}>
            {t("footer.newsletter")}
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <TextField
              variant="outlined"
              placeholder={t("footer.emailPlaceholder")}
              size="small"
              sx={{ width: "50%", backgroundColor: "#ffffff", borderRadius: 1, flex: 4 }}
            />
            <Button variant="contained" sx={{ backgroundColor: "#F066B5", color: "#ffffff", flex: 1 }}>
              {t("footer.subscribe")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
