import React, { useState, useEffect } from "react";
import InputComponent from "../../../../components/InputComponent";
import { useTranslation } from "react-i18next";
import validator from "email-validator";
import userAPI from "services/AvektoiAPIClient";
import { Grid, Button, Typography, Link } from "@mui/material";
import Constants from "common/Constants";
import { Stack } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/@extended/IconButton";
import api from 'services/AvektoiAPIClient';
import TypeSwitcher from 'components/frontOffice/HomePageComponents/TypeSwitcher';
import { useNavigate } from 'react-router-dom';
import { fetcherPost } from "utils/axios";
import { useSnackbar } from "notistack";
import Loader from "components/Loader";

const EmailStep = ({ nextStep, prevStep }) => {
  const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [selection, setSelection] = useState(clientObject.client_type);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)

  const toggleSelection = () => {
    const newSelection = selection === Constants.CLIENT_TYPE.PERSONAL ? Constants.CLIENT_TYPE.PROFESSIONAL : Constants.CLIENT_TYPE.PERSONAL;
    setSelection(newSelection);
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...clientObject, client_type: newSelection }));
  };

  useEffect(() => {
    let clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    setSelection(clientObject.client_type);

    let localClient = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));
    if (clientObject?.email && localClient?.verifiedPassword) {
      nextStep({...clientObject});
      return;
    }
    clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
    if (clientObject.email) {
      setEmail(clientObject.email);
    }
  }, [nextStep]);

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [email]);

  const handleNextClick = async () => {
    if (validator.validate(email)) {
      setError("");
      setIsLoading(true)
      try {
        const response = await userAPI.checkEmail(email);
        if (response === true) {
          setError("already_used");
        } else {
          const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
          sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...clientObject, email: email }));
          const verificationResponse = await userAPI.sendEmailVerification(email);
          if (verificationResponse) {
            console.log("Email verification sent:", verificationResponse);
          } else {
            console.error("Failed to send email verification");
          }
          const newClientObject = {
            first_name: clientObject.first_name,
            last_name: clientObject.last_name,
            phone_number: clientObject.phone_number,
            email: email,
            client_type: clientObject.client_type,
            country_code: clientObject.country_code
          };
          const createClientResponse = await api.createClient(newClientObject);

          // send mail to create password
          await fetcherPost(['emails/send-email', {
            email: email,
            subject: 'TENDANZ - CREATE YOUR PASSWORD',
            message: `Bonjour ${clientObject.first_name}, ${t("signup.step4.createNewPassword")}: ${Constants.DOMAIN_URL}sign-up-more-information?id=${createClientResponse.id}`
          }])
          console.log(`${t("signup.step4.createNewPassword")}: ${Constants.DOMAIN_URL}sign-up-more-information?id=${createClientResponse.id}`);
          enqueueSnackbar(t("signup.step4.checkMailContent"), {variant: 'success'});

          // we cannot create a new user with 'date_of_birth', 'client_type' fields
          // so we will update the date of birth and client type after creating
          await api.updateClient(createClientResponse.id, "date_of_birth", clientObject.date_of_birth);
          await api.updateClient(createClientResponse.id, "client_type", clientObject.client_type);
          setIsLoading(false)
          if (createClientResponse.id != null) {
            localStorage.setItem(Constants.ACCESS_AUTH_TOKEN, createClientResponse.login_token);
            sessionStorage.setItem(Constants.ACCESS_AUTH_TOKEN, createClientResponse.login_token);
            localStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({...createClientResponse, ...clientObject}));
            sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({...clientObject, email: email, verifiedPassword: 'pending'}));
            sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(createClientResponse));
            nextStep({ email });
          } else {
            setIsLoading(false)
            setError('creation_failed');
          }
        }
      } catch (ex) {
        enqueueSnackbar(ex.message, {variant: 'default'});
      }
      
    } else {
      setError("invalid");
    }
  };

  const handleBackClick = () => {
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT))
    const { isPhoneVerified, ...others } = clientObject
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(others));
    prevStep();
  };

  return (
    <>
      {isLoading && <Loader />}
      <TypeSwitcher selection={selection} toggleSelection={toggleSelection} homePage={true} />

      <Typography variant="h2">{t("signup.step4.title1")}</Typography>
      <Typography variant="h2">{t("signup.step4.title2")}</Typography>
      <div style={{ marginTop: 30 }}>
        <Typography variant="h5">{t("signup.step4.instructions")}</Typography>
        <Grid container>
          
          <Grid item xs={12}>
            <div style={{ maxWidth: 350 }}>
              <InputComponent
                label={t("signup.step4.email")}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={error === "already_used" || error === "invalid"}
              />
            </div>
            {error === 'already_used' && (
              <Typography variant="body2" color="error" marginTop="-10px" marginBottom="10px">
                {t('signup.step4.errorMessage')}
                <Link component="button" variant="body2" marginTop="-10px" marginBottom="10px" sx={{ textDecoration: "underline" }} onClick={() => navigate('/login')}>
                  {t('signup.step4.linkLogin')}
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2}>
          <Button size="large" disabled={!email || error === "already_used"} variant="contained" color="primary" style={{ width: 250 }} onClick={handleNextClick}>
            {t("signup.step4.sendMail")}
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default EmailStep;
