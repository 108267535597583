// material-ui
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project-imports
import AuthWrapper from 'sections/auth/AuthWrapper';
import AuthCodeVerification from 'sections/auth/auth-forms/AuthCodeVerification';
import Logo from 'components/logo';
import AuthDivider from 'sections/auth/AuthDivider';
import LanguageSwitcher from 'components/LanguageSwitcher';
// ================================|| CODE VERIFICATION ||================================ //

export default function CodeVerification() {
  let email = window.localStorage.getItem('email');
  let finalArr = [];

  if (email) {
    let emailSplit = email.split('');
    let len = emailSplit.indexOf('@');
    emailSplit.forEach((item, pos) => {
      pos >= 1 && pos <= len - 2 ? finalArr.push('*') : finalArr.push(emailSplit[pos]);
    });
  }
  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <AuthDivider />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="h3">Enter Verification Code</Typography>
            <Typography color="secondary">We send you on mail.</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography>We`ve send you code on {email && finalArr.length > 0 ? finalArr.join('') : '****@company.com'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <AuthCodeVerification />
        </Grid>
        <Grid item xs={12}>
          <AuthDivider/>
        </Grid>
        <Grid item xs={12} >
          <div style={{textAlign:'center'}}>
            <LanguageSwitcher/>
          </div>
        </Grid>     
      </Grid>
    </AuthWrapper>
  );
}
