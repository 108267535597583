import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import HomePageLayout from 'layouts/frontOffice/HomePageLayout';
import CardComponent from 'components/frontOffice/HomePageComponents/SmallCardComponent';
import TitleSection from 'components/frontOffice/HomePageComponents/TitleSectionComponent';
import { Stack , useMediaQuery ,Grid} from '@mui/material';
import familyAnimation  from "../../assets/homePageAssets/Family.json";
import professionalAnimation from "../../assets/homePageAssets/homepage1.svg";
import FeatureCard from 'components/frontOffice/HomePageComponents/FeatureCardComponent';
import Indemnite from "assets/homePageAssets/Indemnite.json"
import Psychologist from "assets/homePageAssets/Psychologist.json"
import Administration from "assets/homePageAssets/administration.json"
import { useNavigate } from 'react-router-dom';
import TypeSwitcher from 'components/frontOffice/HomePageComponents/TypeSwitcher';
import ServiceCardComponent from 'components/frontOffice/HomePageComponents/ServiceCardComponent';
import Constants from 'common/Constants';
import houseAnimation  from "../../assets/homePageAssets/House.json";
import workAnimation  from "../../assets/homePageAssets/work.json";
import familyAnimation1  from "../../assets/homePageAssets/homepage2.svg";
import teamwork  from "../../assets/homePageAssets/Teamwork.json";
import Building from "../../assets/homePageAssets/Building.json";
import Business from "../../assets/homePageAssets/Business.json";
import ButtonComponent from "components/ButtonComponent";


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [selection, setSelection] = useState(Constants.CLIENT_TYPE.PERSONAL);
  const toggleSelection = () => {
    setSelection((prevSelection) => (prevSelection === Constants.CLIENT_TYPE.PERSONAL ? Constants.CLIENT_TYPE.PROFESSIONAL : Constants.CLIENT_TYPE.PERSONAL));
  };

  const handleLoginClick = () => {
    navigate('/sign-up');
  };

  const [clientType, setClientType] = useState('PERSONAL')

  useEffect(() => {
    setClientType('PERSONAL')
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({...clientObject, client_type: clientType}));
  }, []);

  const handleClientType = (type) => {
    setClientType(type)
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) | {};
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({...clientObject, client_type: type}));
    navigate('/sign-up')
    
  }

  return (
    <HomePageLayout handleLoginClick={handleLoginClick}>
      <Container>
        <TitleSection
          titleParts={[t('homePage.title.part1'), t('homePage.title.part2')]}
          titleSizes={isMobile ? ["2.2rem", "2.2rem"] : ["3rem", "3rem"]}
          titleColors={["#282828", "#F066B5"]}
          paragraph={t('homePage.description')}
          isMobile={isMobile}
        />

        <TitleSection
          titleParts={[t('homePage.discoverRates.title')]}
          titleSizes={isMobile ? ["1.3rem"] : ["1.5rem"]}
          titleColors={["#F066B5"]}
          paragraph={t('homePage.discoverRates.description')}
          isMobile={isMobile}

        />

        <Stack direction="row" justifyContent="center" spacing={isMobile? "20%" : "30%"} marginBottom={5}>
          <CardComponent
            active={clientType === 'PERSONAL'}
            text={t('homePage.typeSwitcher.personal')}
            icon={familyAnimation1}
            onClick={() => handleClientType('PERSONAL')}
          />
          <CardComponent
            text={t('homePage.typeSwitcher.professional')}
            icon={professionalAnimation}
            active={clientType === 'PROFESSIONAL'}
            onClick={() => handleClientType('PROFESSIONAL')}
          />
        </Stack>

        <TitleSection
          titleParts={[t('homePage.completeInsurance.title'), t('homePage.completeInsurance.subtitle')]}
          titleSizes={isMobile ? ["1.6rem", "1.6rem"] : ["2rem", "2rem"]}
          titleColors={["#F066B5","#282828" ]}
        />

        {!isMobile ? <div style={{ display: "flex", justifyContent: "space-around", marginBottom:"50px" }}>
          <FeatureCard
            number={1}
            icon={Indemnite}
            title={t('homePage.features.financialCompensation.title')}
            description={t('homePage.features.financialCompensation.description')}
            isAnimation={true}
          />
          <FeatureCard
            number={2}
            icon={Psychologist}
            title={t('homePage.features.psychologicalSupport.title')}
            description={t('homePage.features.psychologicalSupport.description')}
            isAnimation={true}
          />
          <FeatureCard
            number={3}
            icon={Administration}
            title={t('homePage.features.administrativeAssistance.title')}
            description={t('homePage.features.administrativeAssistance.description')}
            isAnimation={true}
          />
        </div> :
         <Grid
         container
         spacing={3}
         justifyContent="center"
         alignItems="center"
         sx={{ marginBottom: "50px" }}
       >
         <Grid item xs={12} sm={6} md={4}>
         <FeatureCard
            number={1}
            icon={Indemnite}
            title={t('homePage.features.financialCompensation.title')}
            description={t('homePage.features.financialCompensation.description')}
            isAnimation={true}
            isMobile = {isMobile}
          />
          <FeatureCard
            number={2}
            icon={Psychologist}
            title={t('homePage.features.psychologicalSupport.title')}
            description={t('homePage.features.psychologicalSupport.description')}
            isAnimation={true}
            isMobile = {isMobile}

          />
          <FeatureCard
            number={3}
            icon={Administration}
            title={t('homePage.features.administrativeAssistance.title')}
            description={t('homePage.features.administrativeAssistance.description')}
            isAnimation={true}
            isMobile = {isMobile}

          />
          </Grid>
          </Grid>}

        <TitleSection
          titleParts={[t('homePage.coveringInsurance.title'), t('homePage.coveringInsurance.subtitle')]}
          titleSizes={ isMobile ? ["1.6rem", "1.6rem"] :["2rem", "2rem"]}
          titleColors={["#F066B5","#282828" ]}
          paragraph={
            <>
              {(t('homePage.coveringInsurance.description', { returnObjects: true }) || []).map((line, index) => (
                <React.Fragment key={index}>
                  &#10003; {line}
                  <br />
                </React.Fragment>
              ))}
            </>
          }
          fontSize="15px"
          isMobile={isMobile}

        />

        <TypeSwitcher selection={selection} toggleSelection={toggleSelection}  homePage={true}/>

        {selection === Constants.CLIENT_TYPE.PERSONAL ? (
          !isMobile ?
          <div style={{ display: "flex", justifyContent: "space-around", marginBottom:"50px" }}>
            <ServiceCardComponent
              animationData={houseAnimation}
              title={t('homePage.personal.house.title')}
              bulletPoints={t('homePage.personal.house.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
            <ServiceCardComponent
              animationData={workAnimation}
              title={t('homePage.personal.work.title')}
              bulletPoints={t('homePage.personal.work.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
            <ServiceCardComponent
              animationData={familyAnimation}
              title={t('homePage.personal.family.title')}
              bulletPoints={t('homePage.personal.family.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
          </div> :
          <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ marginBottom: "50px" }}
        >
          <Grid item xs={12} sm={6} md={4}>
          <ServiceCardComponent
              animationData={houseAnimation}
              title={t('homePage.personal.house.title')}
              bulletPoints={t('homePage.personal.house.points', { returnObjects: true }) || []}
              isAnimation={true}
              isMobile ={isMobile}
            />
            <ServiceCardComponent
              animationData={workAnimation}
              title={t('homePage.personal.work.title')}
              bulletPoints={t('homePage.personal.work.points', { returnObjects: true }) || []}
              isAnimation={true}
              isMobile ={isMobile}

            />
            <ServiceCardComponent
              animationData={familyAnimation}
              title={t('homePage.personal.family.title')}
              bulletPoints={t('homePage.personal.family.points', { returnObjects: true }) || []}
              isAnimation={true}
              isMobile ={isMobile}

            />

            </Grid>
            </Grid>

        ) : (
          !isMobile?
          <div style={{ display: "flex", justifyContent: "space-around", marginBottom:"50px" }}>
            <ServiceCardComponent
              animationData={Building}
              title={t('homePage.professional.local.title')}
              bulletPoints={t('homePage.professional.local.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
            <ServiceCardComponent
              animationData={Business}
              title={t('homePage.professional.activity.title')}
              bulletPoints={t('homePage.professional.activity.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
            <ServiceCardComponent
              animationData={teamwork}
              title={t('homePage.professional.employees.title')}
              bulletPoints={t('homePage.professional.employees.points', { returnObjects: true }) || []}
              isAnimation={true}
            />
          </div> :
            <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{ marginBottom: "50px" }}
          >
            <Grid item xs={12} sm={6} md={4}>
            <ServiceCardComponent
            animationData={Building}
            title={t('homePage.professional.local.title')}
            bulletPoints={t('homePage.professional.local.points', { returnObjects: true }) || []}
            isAnimation={true}
            isMobile={isMobile}

          />
          <ServiceCardComponent
            animationData={Business}
            title={t('homePage.professional.activity.title')}
            bulletPoints={t('homePage.professional.activity.points', { returnObjects: true }) || []}
            isAnimation={true}
            isMobile={isMobile}

          />
          <ServiceCardComponent
            animationData={teamwork}
            title={t('homePage.professional.employees.title')}
            bulletPoints={t('homePage.professional.employees.points', { returnObjects: true }) || []}
            isAnimation={true}
            isMobile={isMobile}

          />
          </Grid>
          </Grid>
        )}
{ isMobile &&
<Stack
          direction="row"
          alignItems="center"
          spacing={1}
          marginTop="-35px"
          marginBottom="35px"

        >
          <ButtonComponent
            label={t("homePage.requestQuote")}
            width="150px"
            height="40px"
            onClick={() => navigate('/sign-up')}
          />

          <ButtonComponent
            label={t("homePage.login")}
            width="150px"
            height="40px"
            onClick={() => navigate('/login')}
          />
       
        </Stack>}
      </Container>
    </HomePageLayout>
  );
};

export default HomePage;
