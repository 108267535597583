import axios from "axios";
import Constants from "../common/Constants";

class Utils {
    static GetApiBaseUrl() {
        return Constants.APP_API_BASE_URL;
    }

    static FormatDateTime(input, dateOnly) {
        // response format: 2024-07-28T12:00:00.000Z
        const date = input.substring(0,10);
        const time = input.substring(11,19);
        return dateOnly ? date : date + " " + time;
    }

    static AxiosConvertToFormData(data) {
        return axios.toFormData(data);
    }
}
  
export default Utils;