import React from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import { ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const getFileIcon = (fileType) => {
    const iconStyle = { fontSize: 35 }; // Increase icon size

    switch (fileType) {
        case 'pdf':
            return <PictureAsPdfIcon sx={iconStyle} />;
        case 'png':
        case 'jpg':
        case 'jpeg':
            return <ImageIcon sx={iconStyle} />;
        case 'doc':
        case 'docx':
            return <DescriptionIcon sx={iconStyle} />;
        default:
            return <InsertDriveFileIcon sx={iconStyle} />;
    }
};

const FileUploaded = ({ file }) => {
    const { file_type, file_url, file_name } = file;

    return (
        <ListItem
            sx={{ paddingLeft: 0 }} // Add padding to the ListItem
            secondaryAction={
                <IconButton
                    edge="end"
                    aria-label="download"
                    href={file_url}
                    download={file_url}
                    target="_blank" // Open the link in a new tab
                    rel="noopener noreferrer" // Improve security when opening in a new tab
                >
                    <DownloadIcon />
                </IconButton>
            }
        >
            <ListItemIcon sx={{ paddingRight: 2 }}> {/* Add padding between the icon and the text */}
                {getFileIcon(file_type)}
            </ListItemIcon>
            <ListItemText primary={file_name} />
        </ListItem>
    );
};

export default FileUploaded;