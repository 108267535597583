import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useDropzone } from "react-dropzone";
import RejectionFiles from "./RejectionFiles";
import PlaceholderContent from "./PlaceholderContent";
import FilesPreview from "./FilesPreview";
import { DropzopType } from "config";
import { uploadDocuments } from "services/DocumentsService";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { openSnackbar } from '../../../api/snackbar';

const fileTypeMapping = {
  'INCIDENT_ILLNESS': {
      acceptedFiles: {
          'application/pdf': ['.pdf'],
          'image/jpeg': ['.jpeg', '.heic'],
      },

  },
  'HOUSE_HARRASSMENT_VIOLENCE': {
      acceptedFiles: {
          'image/jpeg': ['.jpeg', '.heic'],
          'application/pdf': ['.pdf'],
      },
  },
  'TERRORIST_ATTACK': {
      acceptedFiles: {
          'image/jpeg': ['.jpeg', '.heic'],
          'application/pdf': ['.pdf'],
      },
  },
  'DEATH_RELATIVE': {
      acceptedFiles: {
          'image/jpeg': ['.jpeg', '.heic'],
      },
  },
  'PREMATURE_BIRTH': {
      acceptedFiles: {
          'image/jpeg': ['.jpeg', '.heic'],
          'application/pdf': ['.pdf'],
      },
  },
  'TRAUMA_WITNESS': {
      acceptedFiles: {
          'application/pdf': ['.pdf'],
      },
  },
  'DEATH_PET': {
      acceptedFiles: {
          'image/jpeg': ['.jpeg', '.heic'],
          'application/pdf': ['.pdf'],
      },
  },
  'FLOOD': {
      acceptedFiles: {
          'image/jpeg': ['.jpeg', '.heic'],
          'application/pdf': ['.pdf'],
      },
  },
  'FIRE': {
      acceptedFiles: {
          'image/jpeg': ['.jpeg', '.heic'],
          'application/pdf': ['.pdf'],
      },
  },
  'THEFT': {
      acceptedFiles: {
          'image/jpeg': ['.jpeg', '.heic'],
          'application/pdf': ['.pdf'],
      },
  },
  'MENTAL_HEALTH': {
      acceptedFiles: {
          'application/pdf': ['.pdf'],
          'image/jpeg': ['.jpeg', '.heic'],
      },
  },
  'WORK_HARRASSMENT': {
      acceptedFiles: {
          'application/pdf': ['.pdf'],
          'image/jpeg': ['.jpeg', '.heic'],
      },
  },
  'LAYOFF': {
      acceptedFiles: {
          'application/pdf': ['.pdf'],
          'image/jpeg': ['.jpeg', '.heic'],
      },
  },
};


const DropzoneWrapper = styled("div")(({ theme }) => ({
    outline: "none",
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    border: "1px dashed ",
    borderColor: theme.palette.secondary.main,
    "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

export default function MultiFileUpload({ showList = false, type, sx, onUpload, onRemoveFile, claimType }) {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const { acceptedFiles, requiresEsignature } = fileTypeMapping[claimType] || {};

    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        accept: acceptedFiles,
        multiple: true,
        onDrop: async (acceptedFiles) => {
            setFiles((prev) => [...prev, ...acceptedFiles]);
        },
    });

    const onRemoveAll = () => {
        setFiles([]);
        onUpload({ data: [], isError: true });
    };

    const onRemove = (file) => {
        const filteredItems = files.filter((_file) => _file !== file);
        setFiles(filteredItems);
        onRemoveFile(file.name);
    };

    const handleUpload = async () => {
        try {
            setIsLoading(true);
            const response = await uploadDocuments(files);
            onUpload({ data: response.data.records });
            openSnackbar({
                open: true,
                message: 'Upload documents successfully.',
                variant: 'alert',
                alert: { color: 'success' },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
            setIsLoading(false);
        } catch (ex) {
            setError(ex.message);
            setIsLoading(false);
        }
    };

    return (
        <>
            <Box sx={{ width: "100%", ...(type === DropzopType.STANDARD && { width: "auto", display: "flex" }), ...sx }}>
                <Stack {...(type === DropzopType.STANDARD && { alignItems: "center" })}>
                    <DropzoneWrapper
                        {...getRootProps()}
                        sx={{
                            ...(type === DropzopType.STANDARD && { p: 0, m: 1, width: 64, height: 64 }),
                            ...(isDragActive && { opacity: 0.72 }),
                            ...((isDragReject || error) && { color: "error.main", borderColor: "error.light", bgcolor: "error.lighter" }),
                        }}
                    >
                        <input {...getInputProps()} />
                        <PlaceholderContent type={type} />
                    </DropzoneWrapper>
                    {type === DropzopType.STANDARD && files.length > 1 && (
                        <Button variant="contained" color="error" size="extraSmall" onClick={onRemoveAll}>
                            Remove all
                        </Button>
                    )}
                </Stack>
                {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
                {files.length > 0 && <FilesPreview files={files} showList={showList} onRemove={onRemove} type={type} />}
            </Box>

            {type !== DropzopType.STANDARD && files.length > 0 && (
                <Stack direction="row" justifyContent="flex-end" spacing={1.5} sx={{ mt: 1.5 }}>
                    <Button color="inherit" size="small" onClick={onRemoveAll}>
                        Remove all
                    </Button>
                    <LoadingButton loading={isLoading} loadingIndicator="Uploading…" size="small" variant="contained" onClick={handleUpload}>
                        Upload files
                    </LoadingButton>
                </Stack>
            )}
        </>
    );
}

MultiFileUpload.propTypes = {
    error: PropTypes.any,
    showList: PropTypes.bool,
    files: PropTypes.any,
    type: PropTypes.any,
    setFieldValue: PropTypes.any,
    sx: PropTypes.any,
    onUpload: PropTypes.any,
    claimType: PropTypes.string.isRequired,
};
