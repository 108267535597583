import { useCallback, useEffect, useState } from "react"
import { fetcher } from "utils/axios"

export const useQuestionnaire = () => {
  const [questionnaire, setQuestionnaire] = useState()
  const [questions, setQuestions] = useState([])
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    fetchQuestion()
  }, [])
  
  const fetchQuestion = async () => {
    try {
      const response = await fetcher(['questions'])
      if(response?.records.length > 0) {
        const records = response.records.map(item => ({...item, client_answer: ''}))
        setQuestions(records)
        // set initial question
        setQuestionnaire([records[0]])
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const handleClientAnswerQuestion = useCallback((questionIndex, content) => {
    // use JSON parse and stringify to deep clone a object array
    const currentQuestionnaire = JSON.parse(JSON.stringify(questionnaire))
    currentQuestionnaire[questionIndex].client_answer = content

    // add a new question
    const nextIndex = questionIndex + 1
    if(nextIndex < questions.length) {
      setQuestionnaire([...currentQuestionnaire, questions[questionIndex + 1]])
    } else {
      setQuestionnaire([...currentQuestionnaire])
      setDisabled(true)
    }
  }, [questionnaire, questions])
  
  return { questionnaire, disabled, onClientAnswerQuestion: handleClientAnswerQuestion }
}