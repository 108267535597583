import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import './CreateClaimFormLayout.css';
import InputComponent from 'components/InputComponent';
import { useTranslation } from 'react-i18next';
import Constants from 'common/Constants';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import DateTimePickerComponent from 'components/frontOffice/DateTimePickerComponent/DateTimePickerComponent';
import dayjs from 'dayjs';
import CreateClaimChatLayout from '../CreateClaimChatLayout/CreateClaimChatLayout';
import MultiFileUpload from 'components/third-party/dropzone/MultiFile';
import ClaimService from 'services/ClaimService';
import axiosServices from 'utils/axios';
import dateFormat from 'dateformat';
import { Ethereum } from 'iconsax-react';
import * as Yup from 'yup';

const CreateClaimFormLayout = ({ onClose, onRefresh }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [claimType, setClaimType] = useState(null);
  const [claimCause, setClaimCause] = useState("");
  const [claimTitle, setClaimTitle] = useState("");
  const [claimAmount, setClaimAmount] = useState("");
  const [inputValue, setInputValue] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [policyError, setPolicyError] = useState(false);
  const [claimError, setClaimError] = useState(false);
  const [occurrenceDate, setOccurrenceDate] = useState(dayjs());
  const client = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));
  const [policies, setPolicies] = useState([]);
  const [user, setUser] = useState({});
  const [fileRecords, setFileRecords] = useState([]);
  const [filesError, setFilesError] = useState(false);
  const [conversation, setConversation] = useState()

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    getMyPolicies();
  }, []);

  const getMyPolicies = async () => {
    let response = await axiosServices.get("/policies/my-policies");
    setPolicies(response.data);
  };

  const renderClaimTypeMenuItems = () => {
    return Constants.CLAIM.TYPE_LIST.map((type) => {
      return <MenuItem value={type}>{t("claim.type." + type)}</MenuItem>;
    });
  };

  // TODO: Refactor these repetitive handler functions into a single, reusable function to manage input changes efficiently.
  const handleClaimTypeChange = (event) => {
    setClaimType(event.target.value);
  };

  const handleClaimCauseChange = (event) => {
    setClaimCause(event.target.value);
  };

  const handleClaimTitleChange = (event) => {
    setClaimTitle(event.target.value);
  };

  const handleClaimAmountChange = (event) => {
    setClaimAmount(event.target.value);
  };

  const handleOccurrenceDateChange = (value) => {
    setOccurrenceDate(value);
  };

  const handleUploadFiles = ({data, isError = false}) => {
    setFileRecords(data)
    setFilesError(isError)
  }

  const handleRemoveFile = (fileName) => {
    const currentRecords = [...fileRecords].filter(file => file.file_name !== fileName)
    setFileRecords(currentRecords)
  }

  const prepareCreateClaimData = () => {
    const fileList = fileRecords.map((item) => item.file_id);
    return {
      title: claimTitle,
      request_amount: claimAmount,
      policy_id: selectedPolicy.id,
      portfolio_id: Constants.PORTFOLIO_ID_SAMPLE,
      claim_status_id: Constants.STATUS.CLAIM.OPEN.LABEL,
      claim_cause: claimCause,
      claim_type_id: claimType,
      occurence_date: occurrenceDate,
      files: fileList,
      conversation_rev: JSON.stringify(conversation)
    };
  };

  const steps = [
    { label: t('claims.steps.reviewInformation'), description: t('claims.steps.reviewInformationDesc') },
    { label: t('claims.steps.selectPolicy'), description: t('claims.steps.selectPolicyDesc') },
    { label: t('claims.steps.answerQuestions'), description: t('claims.steps.answerQuestionsDesc') },
    { label: t('claims.steps.fillClaim'), description: t('claims.steps.fillClaimDesc') },
    { label: t('claims.steps.uploadEvidence'), description: t('claims.steps.uploadEvidenceDesc') }
  ];

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setPolicyError(false);
    setClaimError(false);
    if (activeStep === steps.length - 1) {
      // validate for uploading files
      if (fileRecords.length === 0) {
        setFilesError(true);
        return;
      }
      createNewClaim();
    } else {
      if (activeStep === 1 && selectedPolicy === null) {
        setPolicyError(true)
        return;
      }

      if (activeStep === 3) {
        if (claimTitle.length === 0 || claimCause.length === 0 || !occurrenceDate || !claimType ) {
          setClaimError(true);
          return;
        }
      }
      setActiveStep(activeStep + 1);
    }
  };

  const createNewClaim = () => {
    let data = prepareCreateClaimData();
    ClaimService.createNewClaim(data)
      .then((response) => {
        if (response) {
          setActiveStep(activeStep + 1);
          console.log(response);
          onRefresh();
        }
      })
      .catch((err) => {
        console.log("create claims error:");
        console.log(err);
      });
  };

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required(t('complaints.errorRequiredPhone')),
    emailContact: Yup.string()
      .email(t('complaints.errorInvalidEmail'))
      .required(t('complaints.errorRequiredEmail')),
    complaintTitle: Yup.string()
      .required(t('complaints.errorRequiredComplaintTitle')),
    complaintDescription: Yup.string()
      .required(t('complaints.errorRequiredComplaintDescription')),
  });

  const renderInformation = () => {
    return (
        <List>
          <ListItem>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={6}>
                <InputComponent
                label={t('claims.client.firstName')}
                  customMargin={true}
                  value={client.first_name}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputComponent
                label={t('claims.client.lastName')}
                  customMargin={true}
                  value={client.last_name}
                  readOnly={true}
                />
              </Grid>
            </Grid>
          </ListItem>
        {client.client_type === Constants.CLIENT_TYPE.PROFESSIONAL &&
            <ListItem>
              <Grid container spacing={{ xs: 5, md: 2 }}>
                <Grid item xs={12} sm={6}>
                  <InputComponent
                  label={t('claims.client.companySIRET')}
                    customMargin={true}
                    value={client.company_id}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputComponent
                  label={t('claims.client.companyName')}
                    customMargin={true}
                    value={client.company_name}
                    readOnly={true}
                  />
                </Grid>
              </Grid>
            </ListItem>
        }
          <ListItem>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={12}>
                <InputComponent
                label={t('claims.client.address')}
                  customMargin={true}
                  value={client.address_line_1 || client.address_line_2}
                  readOnly={true}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={12}>
                <InputComponent
                label={t('claims.client.city')}
                  customMargin={true}
                  value={client.city}
                  readOnly={true}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={6}>
                <InputComponent
                label={t('claims.client.phoneNumber')}
                  customMargin={true}
                  value={client.phone_number}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputComponent
                label={t('claims.client.email')}
                  customMargin={true}
                  value={client.email}
                  readOnly={true}
                />
              </Grid>
            </Grid>
          </ListItem>
        </List>
    );
  }

  const renderPolicyDetails = () => {
    return (
        <List>
          <ListItem>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={12}>
                {policyError && <Alert style={{ marginBottom: 10 }} color="error" icon={<Ethereum variant="Bold" />}>
                {t('claims.errors.selectPolicy')}
                </Alert>}
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="basic-autocomplete"
                options={policies.length > 0 ? policies.map((policy) => ({ id: policy.id, label: `${policy.amendment_number} (${dateFormat(policy.inception_date, 'yyyy-mm-dd')})` })) : []}
                renderInput={(params) => <TextField {...params} placeholder={t('claims.placeholders.enterPolicyNumber')} />}
                  value={selectedPolicy}
                  onChange={(event, newValue) => {
                    setSelectedPolicy(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                />
              </Grid>
            </Grid>
          </ListItem>
        </List>
    );
  }
  
  const handleSaveConversation = (data) => setConversation(data)

  const renderQuestionnaire = () => {
    return (
      <>
        <Chip color="error" label={t('claims.messages.waitingForAIDevelopment')} size="default" variant="light" />
        <CreateClaimChatLayout step={activeStep} theme={theme} onSaveConversation={handleSaveConversation} />
      </>
    );
  }

  const renderClaimForm = () => {
    return (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <List style={{ marginTop: 20, marginBottom: 20 }} sx={{ py: 0 }}>
          {claimError && <Alert style={{ marginBottom: 10 }} color="error" icon={<Ethereum variant="Bold" />}>
            {t('claims.errors.fillAllData')}
          </Alert>}
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography>
                    <DateTimePickerComponent
                      required
                      label={t('claims.layouts.pages.myClaims.createClaimForm.occurrenceDate')}
                      value={occurrenceDate}
                      type={Constants.DATETIME_PICKER.TYPE.DATE_PICKER}
                      onChange={handleOccurrenceDateChange}
                      disableFuture
                    />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography>
                    <FormControl fullWidth>
                      <InputLabel
                        id="claimTypeSelectLabel"
                        sx={{ color: 'gray' }}
                      >
                        {t('claims.layouts.pages.myClaims.createClaimForm.claimType')}
                      </InputLabel>
                      <Select
                        required
                        id="claimTypeSelect"
                        value={claimType}
                        label={t('claims.layouts.pages.myClaims.createClaimForm.claimType')}
                        onChange={handleClaimTypeChange}
                      >
                        {renderClaimTypeMenuItems()}
                      </Select>
                    </FormControl>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack spacing={0.5}>
                  <Typography>
                    <TextField required id="outlined-basic" label={t('claims.layouts.pages.myClaims.createClaimForm.title')} style={{ width: '100%' }} value={claimTitle} onChange={handleClaimTitleChange} />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack spacing={0.5}>
                  <Typography>
                    <TextField
                      id="outlined-multiline-static"
                      fullWidth
                      label={t('claims.layouts.pages.myClaims.createClaimForm.letterOfClaim')}
                      multiline
                      rows={5}
                      value={claimCause}
                      defaultValue=""
                      onChange={handleClaimCauseChange}
                    />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          
        </List>
      </div>
    );
  };

  const renderUpload = () => {
    return (
      <>
        {filesError && (
          <Alert sx={{ my: 0.5 }} color="error" icon={<Ethereum variant="Bold" />}>
            {t('claim.evidence')}
          </Alert>
        )}
        <MultiFileUpload onUpload={handleUploadFiles} onRemoveFile={handleRemoveFile} claimType={claimType} />
      </>
    );
  };

  return (
    <div className="create-claim-form-layout" style={{ width: 600 }}>
      <Stepper activeStep={activeStep} orientation="vertical" size="lg">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box>
                {index === 0 && renderInformation()}
                {index === 1 && renderPolicyDetails()}
                {index === 2 && renderQuestionnaire()}
                {index === 3 && renderClaimForm()}
                {index === 4 && renderUpload()}
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    color={index === steps.length - 1 ? 'primary' : 'secondary'}
                  >
                    {index === steps.length - 1 ? t('claims.buttons.submitNewClaim') : t('claims.buttons.continueNextStep')}
                  </Button>
                  <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    {t('claims.buttons.back')}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Box sx={{ pt: 2 }}>
          <Typography sx={{ color: 'success.main' }}>{t('claims.messages.claimSubmitted')}</Typography>
        </Box>
      )}
    </div>
  );
};

CreateClaimFormLayout.propTypes = {};

CreateClaimFormLayout.defaultProps = {};

export default CreateClaimFormLayout;
