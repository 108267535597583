import React, { useEffect, useState } from 'react';
import InputComponent from 'components/InputComponent';
import { useTranslation } from 'react-i18next';
import Constants, { COMPLAINT } from 'common/Constants';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';

import MultiFileUpload from 'components/third-party/dropzone/MultiFile';
import ComplaintService from 'services/ComplaintService';
import axiosServices from 'utils/axios';
import { Ethereum } from 'iconsax-react';
import dateFormat from 'dateformat';
import * as Yup from 'yup';
import FormHelperText from '@mui/material/FormHelperText';

const CreateComplaintFormLayout = ({ onRefresh }) => {
  const { t } = useTranslation();
  const clientData = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));
  const [formState, setFormState] = useState({
    complaintType: null,
    complaintTitle: null,
    selectedPolicy: null,
    selectedClaim: null,
    complaintDescription: null,
    firstName: clientData.first_name,
    lastName: clientData.last_name,
    phoneNumber: clientData.phone_number,
    emailContact: clientData.email,
    fileRecords: [],
    complaintError: false,
  });

  const [activeStep, setActiveStep] = useState(0);
  const [inputValue, setInputValue] = useState(null);
  const [fileRecords, setFileRecords] = useState([]);
  const [filesError, setFilesError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [claims, setClaims] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false); // State to handle completion

  // Static complaint types
  const complaintTypes = [
    { code: 'TELEMARKETING', name: t('complaints.telemarketing'), type: 'general' },
    { code: 'ONBOARDING', name: t('complaints.onboarding'), type: 'general' },
    { code: 'POLICY', name: t('complaints.policy'), type: 'policy' },
    { code: 'CLAIM', name: t('complaints.claim'), type: 'claim' },
    { code: 'CLIENT_RELATIONSHIP', name: t('complaints.client_relationship'), type: 'general' },
    { code: 'RGPD', name: t('complaints.rgpd'), type: 'general' },
    { code: 'OTHER', name: t('complaints.other'), type: 'general' }
  ];

  const handleUploadFiles = ({data, isError = false}) => {
    setFileRecords(data);
    setFilesError(isError);
  };

  const handleRemoveFile = (fileName) => {
    const currentRecords = fileRecords.filter(file => file.file_name !== fileName);
    setFileRecords(currentRecords);
  };

  const getClaims = async () => {
    try {
      const response = await axiosServices.get('/claims/my-claims');
      setClaims(response.data);
    } catch (error) {
      console.error('Error fetching claims:', error);
    }
  };

  const getPolicies = async () => {
    try {
      const response = await axiosServices.get('/policies/my-policies');
      setPolicies(response.data);
    } catch (error) {
      console.error('Error fetching policies:', error);
    }
  };

  const handleChange = async (field, value) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));
    await validateField(field, value);

    if (field === 'complaintType') {
      if (value.type === 'policy') {
        getPolicies();
      } else if (value.type === 'claim') {
        getClaims();
      } else {
        setPolicies([]);
        setClaims([]);
        setFormState(prevState => ({
          ...prevState,
          selectedClaim: null,
          selectedPolicy: null
        }));
      }
    }

    console.log("Complaint Type:", value); // Debug log to check if state is updating correctly
  };

  const prepareCreateComplaintData = () => {
    const fileList = fileRecords.map((item) => item.file_id);
    return {
      title: formState.complaintTitle,
      description: formState.complaintDescription,
      policy_id: formState.selectedPolicy?.id || null,
      claim_id: formState.selectedClaim?.id || null,
      complaint_type_id: formState.complaintType?.code,
      files: fileList,
      phone_number: formState.phoneNumber,
      email_contact: formState.emailContact
    };
  };

  const steps = [
    {
      label: t('complaints.review_information'),
      description: t('complaints.review_information_desc')
    },
    {
      label: t('complaints.fill_complaint'),
      description: t('complaints.fill_complaint_desc')
    },
    {
      label: t('complaints.upload_evidence'),
      description: t('complaints.upload_evidence_desc')
    },
  ];

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const handleNext = () => {
    setFormState(prevState => ({ ...prevState, complaintError: false }));

    if (activeStep === steps.length - 1) {
      createNewComplaint();
      return;
    }

    const { complaintTitle, complaintDescription, complaintType, phoneNumber, emailContact, selectedClaim, selectedPolicy } = formState;
    if (
      (activeStep === 1 && (!complaintTitle || !complaintDescription || !complaintType
        || (complaintType?.type === 'policy' && !selectedPolicy) || (complaintType?.type === 'claim' && !selectedClaim))) ||
      (activeStep === 0 && (!phoneNumber || !emailContact))
    ) {
      setFormState(prevState => ({ ...prevState, complaintError: true }));
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000);
      return;
    }

    setActiveStep(prevStep => prevStep + 1);
  };

  const createNewComplaint = () => {
    const data = prepareCreateComplaintData();
    ComplaintService.createNewComplaint(data)
      .then(response => {
        if (response) {
          setIsCompleted(true); // Set completion state to true
          onRefresh();
        }
      })
      .catch(err => {
        console.error('Error creating complaint:', err);
      });
  };

  const renderComplaintTypeMenuItems = () => {
    return complaintTypes.map(type => (
      <MenuItem key={type.code} value={type}>
        {type.name}
      </MenuItem>
    ));
  };

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required(t('complaints.errorRequiredPhone')),
    emailContact: Yup.string()
      .email(t('complaints.errorInvalidEmail'))
      .required(t('complaints.errorRequiredEmail')),
    complaintTitle: Yup.string()
      .required(t('complaints.errorRequiredComplaintTitle')),
    complaintDescription: Yup.string()
      .required(t('complaints.errorRequiredComplaintDescription')),
  });

  const validateField = async (name, value) => {
    try {
      const fieldSchema = validationSchema.pick([name]);
      await fieldSchema.validate({ [name]: value }, { abortEarly: false });
      setFormState(prevState => ({ ...prevState, errors: { ...prevState.errors, [name]: '' } }));
    } catch (err) {
      if (err.inner) {
        const newError = err.inner.find(error => error.path === name);
        if (newError) {
          setFormState(prevState => ({ ...prevState, errors: { ...prevState.errors, [name]: newError.message } }));
        }
      }
    }
  };

  const renderInformation = () => (
    <List>
      <ListItem>
        <Grid container spacing={{ xs: 5, md: 2 }}>
          <Grid item xs={12} sm={6}>
            <InputComponent
              label={t('complaints.first_name')}
              customMargin={true}
              value={formState.firstName}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              label={t('complaints.last_name')}
              customMargin={true}
              value={formState.lastName}
              readOnly={true}
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container spacing={{ xs: 5, md: 2 }}>
          <Grid item xs={12} sm={6}>
            <InputComponent
              required={true}
              label={t('complaints.phone_number')}
              customMargin={true}
              value={formState.phoneNumber}
              onChange={(event) => handleChange('phoneNumber', event.target.value)}
              error={Boolean(formState?.errors?.phoneNumber)}
              helperText={formState?.errors?.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              required={true}
              label={t('complaints.email_contact')}
              customMargin={true}
              value={formState.emailContact}
              onChange={(event) => handleChange('emailContact', event.target.value)}
              error={Boolean(formState?.errors?.emailContact)}
              helperText={formState?.errors?.emailContact}
            />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );

  const renderComplaintForm = () => (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <List style={{ marginTop: 20, marginBottom: 20 }} sx={{ py: 0 }}>
        {showAlert && formState.complaintError && (
          <Alert style={{ marginBottom: 10 }} color="error" icon={<Ethereum variant="Bold" />}>
            {t('complaints.please_fill_all_data')}
          </Alert>
        )}
        <ListItem>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={0.5}>
                <Typography>
                  <FormControl fullWidth error={Boolean(formState.errors?.complaintType)}>
                    <InputLabel
                      required={true}
                      id="complaintTypeSelectLabel"
                      sx={{ color: 'gray' }}
                      value={t('complaints.complaint_type')}
                    >
                      {t('complaints.complaint_type')}
                    </InputLabel>
                    <Select
                      id="complaintTypeSelect"
                      value={formState.complaintType || ''}
                      label={t('complaints.complaint_type')}
                      onChange={(event) => handleChange('complaintType', event.target.value)}
                      error={Boolean(formState.errors?.complaintType)}
                    >
                      {renderComplaintTypeMenuItems()}
                    </Select>
                    {formState.errors?.complaintType && (
                      <FormHelperText>{formState.errors?.complaintType}</FormHelperText>
                    )}
                  </FormControl>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </ListItem>
        {[COMPLAINT.TYPE.POLICY, COMPLAINT.TYPE.CLAIM].includes(formState.complaintType?.type) && (
          <ListItem>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="basic-autocomplete"
                  options={
                    formState.complaintType?.type === COMPLAINT.TYPE.POLICY
                      ? policies.map(policy => ({
                          id: policy.id,
                          label: `${policy.amendment_number} (${dateFormat(policy.inception_date, 'yyyy-mm-dd')})`
                        }))
                      : claims.map(claim => ({
                          id: claim.id,
                          label: `${claim.claim_reference_number} - ${claim.description}`
                        }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      placeholder={
                        formState.complaintType?.type === COMPLAINT.TYPE.POLICY
                          ? t('complaints.policy_placeholder')
                          : t('complaints.claim_placeholder')
                      }
                      error={Boolean(formState.errors?.selectedClaim || formState.errors?.selectedPolicy)}
                      helperText={formState.errors?.selectedClaim || formState.errors?.selectedPolicy}
                    />
                  )}
                  value={
                    formState.complaintType?.type === COMPLAINT.TYPE.CLAIM
                      ? formState.selectedClaim
                      : formState.selectedPolicy
                  }
                  onChange={(event, newValue) =>
                    handleChange(
                      formState.complaintType?.type === COMPLAINT.TYPE.CLAIM
                        ? 'selectedClaim'
                        : 'selectedPolicy',
                      newValue
                    )
                  }
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                />
              </Grid>
            </Grid>
          </ListItem>
        )}
        <ListItem>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={0.5}>
                <Typography>
                  <TextField
                    required
                    id="outlined-basic"
                    label={t('complaints.complaint_title')}
                    fullWidth
                    value={formState.complaintTitle}
                    onChange={(event) => handleChange('complaintTitle', event.target.value)}
                    error={Boolean(formState.errors?.complaintTitle)}
                    helperText={formState.errors?.complaintTitle}
                  />
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={0.5}>
                <Typography>
                  <TextField
                    id="outlined-multiline-static"
                    fullWidth
                    required
                    label={t('complaints.complaint_description')}
                    multiline
                    rows={5}
                    value={formState.complaintDescription}
                    onChange={(event) => handleChange('complaintDescription', event.target.value)}
                    error={Boolean(formState.errors?.complaintDescription)}
                    helperText={formState.errors?.complaintDescription}
                  />
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </div>
  );

  const renderUpload = () => {
    return (
      <>
        {filesError && (
          <Alert sx={{ my: 0.5 }} color="error" icon={<Ethereum variant="Bold" />}>
            Please upload your evidence
          </Alert>
        )}
        <MultiFileUpload onUpload={handleUploadFiles} onRemoveFile={handleRemoveFile} />
      </>
    );
  };

  return (
    <div className="create-complaint-form-layout" style={{ width: 600 }}>
      {!isCompleted ? (
        <Stepper activeStep={activeStep} orientation="vertical" size="md">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box>
                  {index === 0 && renderInformation()}
                  {index === 1 && renderComplaintForm()}
                  {index === 2 && renderUpload()}
                </Box>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      color={index === steps.length - 1 ? 'primary' : 'secondary'}
                    >
                      {index === steps.length - 1 ? t('complaints.submit_new_complaint') : t('complaints.continue_next_step')}
                    </Button>
                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      {t('complaints.back')}
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Box sx={{ pt: 2 }}>
          <Typography variant="h4" sx={{ color: 'success.main', textAlign: 'center' }}>
            {t('complaints.we_have_received_your_complaint')}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {t('complaints.thank_you_for_submitting')}
          </Typography>
        </Box>
      )}
    </div>
  );
};

CreateComplaintFormLayout.propTypes = {};

CreateComplaintFormLayout.defaultProps = {};

export default CreateComplaintFormLayout;
