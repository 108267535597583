import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import UserAvatar from 'sections/apps/chat/UserAvatar';
import { ThemeMode } from 'config';
import { Box, Divider, styled } from '@mui/material';
import InputComponent from 'components/InputComponent';
import { useQuestionnaire } from 'hooks/useQuestionnaire';

// ==============================|| CHAT - HISTORY ||============================== //

const ChatBox = styled(Card)(({primary, theme}) => {
  return !primary ? {
    display: 'inline-block',
    float: 'left',
    background: theme.palette.mode === ThemeMode.DARK ? 'background.background' : 'common.white',
    boxShadow: 'none'
  }: {
    display: 'inline-block',
    float: 'right',
    background: theme.palette.primary.main,
    boxShadow: 'none',
    ml: 1
  }
})

export default function CreateClaimChatLayout({ theme, user, onSaveConversation, step }) {
  const bottomRef = useRef(null);
  const [message, setMessage] = useState('')

  const { questionnaire, disabled, onClientAnswerQuestion } = useQuestionnaire()
  
  const handleAnswer = (event) => {
    if(event.key === 'Enter') {
      onClientAnswerQuestion(questionnaire.length - 1 || 0, message)
      setMessage('')
    }
  }

  useEffect(() => {
    // questionnaire is step 2, so step is 3, that means client has go to next step
    // so we have saved conversation data to add into claim table
    if(step === 3) {
      onSaveConversation(questionnaire)
    }
  }, [onSaveConversation, step, questionnaire])
  

  return (
    <Grid container spacing={2.5} style={{ border: '1px solid lightgray', margin: 5, borderRadius: 20, padding: 10 }}>
      <Grid item xs={12}>
        {questionnaire?.map(item => (
          <Box key={item.id}>
            <Stack direction="row" spacing={1.25} alignItems="flex-start">
              <UserAvatar user={{ online_status: 'available', avatar: 'avatar', name: 'avektoi' }} />
              <Grid container>
                <Grid item xs={12} sm={7}>
                  <ChatBox>
                    <CardContent sx={{ p: 1, pb: '8px !important' }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" color="text.primary" sx={{ overflowWrap: 'anywhere' }}>
                            {item.content}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </ChatBox>
                </Grid>
              </Grid>
            </Stack>
            {item.client_answer && 
              <Stack spacing={1.25} direction="row" alignItems="flex-start">
                <Grid container justifyContent="flex-end">
                  <Grid item xs={2} md={3} xl={4} />
                  <Grid item xs={10} md={9} xl={8}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-start">
                      <ChatBox primary>
                        <CardContent sx={{ p: 1, pb: '8px !important', width: 'fit-content', ml: 'auto' }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography variant="h6" color={theme.palette.common.white} sx={{ overflowWrap: 'anywhere' }}>
                                {item.client_answer}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </ChatBox>
                    </Stack>
                  </Grid>
                </Grid>
                <UserAvatar user={{ online_status: 'available', avatar: 'avatar-1.png', name: 'User 1' }} />
              </Stack>
            }
          </Box>
        ))}
        <Divider sx={{mt: 3}} />
        <Stack>
          <InputComponent
            fullWidth
            multiline
            rows={4}
            placeholder="Your Message..."
            value={message}
            disabled={disabled}
            onChange={(e) => setMessage(e.target.value.length <= 1 ? e.target.value.trim() : e.target.value)}
            onKeyDown={handleAnswer}
            variant="standard"
          />
        </Stack>
      </Grid>
      <Grid item ref={bottomRef} />
    </Grid>
  );
}

CreateClaimChatLayout.propTypes = { theme: PropTypes.any, user: PropTypes.any };
