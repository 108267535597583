import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, ListItemText } from '@mui/material';

// project import
import MainCard from 'components/MainCard';

// assets
import dateFormat from "dateformat";
import { format as currencyFormatter } from 'currency-formatter';
import { fetcher } from 'utils/axios';
import dayjs from 'dayjs';
import FileUploaded from 'components/FileUploaded';

export default function ExpandingClaimDetail({ data }) {
  const { t } = useTranslation();
  const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [policyDetail, setPolicyDetail] = useState();

  useEffect(() => {
    // Get policy details
    fetcher(`/policies/detail/${data.policy_id}`).then(data => setPolicyDetail(data));
  }, [data]);

  const renderStatus = (value) => {
    switch (value) {
      case 'CLAIMED':
        return <Chip color="primary" label={t('claims.status.claimed')} size="Default" variant="light" />;
      case 'REJECTED':
        return <Chip color="error" label={t('claims.status.rejected')} size="Default" variant="light" />;
      case 'APPROVED':
        return <Chip color="success" label={t('claims.status.approved')} size="Default" variant="light" />;
      case 'PROCESSING':
        return <Chip color="warning" label={t('claims.status.processing')} size="Default" variant="light" />;
      case 'OPEN':
        return <Chip color="info" label={t('claims.status.open')} size="Default" variant="light" />;
      default:
        return <Chip color="primary" label={t(`status.${value.toLowerCase()}`)} size="Default" variant="light" />;
    }
  };

  const renderPolicyStatus = (value) => {
    switch (value) {
      case 'EXPIRED':
        return <Chip color="error" label={t('claims.status.expired')} size="Default" variant="light" />;
      case 'ACTIVE':
        return <Chip color="success" label={t('claims.status.active')} size="Default" variant="light" />;
      default:
        return <Chip color="primary" label={t(`status.${value.toLowerCase()}`)} size="Default" variant="light" />;
    }
  };

  const renderClaimButton = (value) => {
    switch (value) {
      case 'APPROVED':
      case 'CLAIMED':
        return <Button variant="contained" color="secondary" onClick={underConstruction}>{t('claims.buttons.checkTransaction')}</Button>;
      case 'REJECTED':
        return <Button variant="contained" color="primary" onClick={underConstruction}>{t('claims.buttons.openNewClaim')}</Button>;
      case 'OPEN':
      case 'PROCESSING':
        return <Button variant="contained" color="error" onClick={underConstruction}>{t('claims.buttons.cancelClaim')}</Button>;
      default:
        return <Button variant="contained" color="secondary" onClick={underConstruction}>{t('claims.buttons.default')}</Button>;
    }
  };

  const underConstruction = () => {
    alert(t('claims.alerts.underConstruction'));
  };

  return (
    <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }}>
      <Grid item xs={12} sm={5} md={4} xl={3.5}>
        <MainCard title={t('claims.policyDetails')}>
          {policyDetail && (
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.policyNumber')}</Typography>
                      <Typography variant="h3">
                        {policyDetail.amendment_number}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    {/*<Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.status')}</Typography>
                      <Typography>{renderPolicyStatus('ACTIVE')}</Typography>
                    </Stack>*/}
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.registrationDate')}</Typography>
                      <Typography>{dateFormat(policyDetail.inception_date, 'yyyy-mm-dd HH:MM')}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.expirationDate')}</Typography>
                      <Typography>{dateFormat(policyDetail.termination_date, 'yyyy-mm-dd HH:MM')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.paymentFrequency')}</Typography>
                      <Typography>{policyDetail.payment_frequency}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          )}
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={7} md={8} xl={8.5}>
        <Stack spacing={2.5}>
          <MainCard title={t('claims.claimDetails')}>
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimNumber')}</Typography>
                      <Typography variant="h3">{data.claim_reference_number}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimAmount')}</Typography>
                      <Typography variant="h3">{currencyFormatter(data.request_amount, { code: 'EUR' })}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.createdDate')}</Typography>
                      <Typography>{dateFormat(data.created_at, 'yyyy-mm-dd HH:MM')}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.occurrenceDate')}</Typography>
                      <Typography>{dateFormat(data.occurence_date, 'yyyy-mm-dd')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimTitle')}</Typography>
                      <Typography>{data.title}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimStatus')}</Typography>
                      <Typography>{renderStatus(data.claim_status_id)}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimType')}</Typography>
                      <Typography>{data.claim_type_id}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimContent')}</Typography>
                      <Typography>{data.claim_cause}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.evidenceDocuments')}</Typography>
                      {data.documents.length > 0 && data.documents.map((document) => (
                          <FileUploaded file={document} key={document.id} />
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimHistory')}</Typography>
                      <Typography>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          {Array.isArray(data.claim_history) && data.claim_history.length && data.claim_history.map(item => {
                            const formattedDate = dayjs(item.updated_at).format('MMM DD YYYY h:mm');
                            return (
                              <ListItem key={item.id} sx={{ px: 0, justifyContent: 'space-between' }}>
                                <Chip
                                  color="info"
                                  label={`${item.old_status} → ${item.new_status}`}
                                  variant="light"
                                  sx={{ mr: 4, minWidth: 140, textAlign: 'center' }}
                                />
                                <ListItemText
                                  primary={formattedDate}
                                  sx={{ display: 'flex', justifyContent: 'end' }}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Typography>
                    </Stack>
                  </Grid>  
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      {renderClaimButton(data.claim_status_id)}
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
          </List>
        </MainCard>
      </Stack>
    </Grid>
  </Grid>
);
}

ExpandingClaimDetail.propTypes = { data: PropTypes.any };