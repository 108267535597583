import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import constants from '../common/Constants';

const CustomTextField = styled(TextField)(({ theme, error, customMargin, fullWidth, customBorder, customStyles }) => ({
    marginTop: customMargin ? 0 : 18,
    marginBottom: customMargin ? 0 : 18,
    width: fullWidth ? '100%' : 300,
    height :customStyles?.height,
    borderRadius: customStyles?.borderRadius,

    '& .MuiOutlinedInput-root': {
        '& fieldset': {

        borderColor: error ? constants.APP_ERROR_COLOR : customStyles?.borderColor || constants.APP_QUATERNARY_ACCENT_COLOR_DEFAULT,
        },
        '&:hover fieldset': {
            borderColor: error ? constants.APP_ERROR_COLOR : customStyles?.hoverBorderColor || constants.APP_QUATERNARY_ACCENT_COLOR_DEFAULT,
        },
        '&.Mui-focused fieldset': {
            borderColor: error ? constants.APP_ERROR_COLOR : customStyles?.focusedBorderColor || constants.APP_QUATERNARY_ACCENT_COLOR_DEFAULT,
        },
    },
    '& .MuiInputLabel-root': {
        fontSize: customStyles?.labelFontSize || 16,
        color: error ? constants.APP_ERROR_COLOR : customStyles?.labelColor || constants.APP_TEXT_COLOR,
        '&.Mui-focused': {
            transform: customStyles?.labelTransform || 'translate(14px, -6px) scale(0.75)',
            color: error ? constants.APP_ERROR_COLOR : customStyles?.focusedLabelColor || constants.APP_TEXT_COLOR,
        },
    },
    '& .MuiFormHelperText-root': {
        fontSize: customStyles?.helperTextFontSize || 12,
        marginTop: customStyles?.helperTextMarginTop || 3,
        marginLeft: customStyles?.helperTextMarginLeft || 0,
        color: error ? constants.APP_ERROR_COLOR : customStyles?.helperTextColor || constants.APP_TEXT_COLOR,
        maxWidth: customStyles?.helperTextMaxWidth || 300,
    },
}));

const InputComponent = ({ label, type, value, onChange, helperText, error, customMargin, disabled, customBorder, required, placeholder, onKeyDown }) => {
    const [inputType, setInputType] = useState(type);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        setInputType(showPassword ? 'password' : 'text');
    };

    return (
        <CustomTextField
            required={required}
            label={label}
            type={inputType}
            value={value}
            onChange={onChange}
            helperText={helperText}
            error={error}
            fullWidth
            variant="outlined"
            customMargin={customMargin}
            disabled={disabled}
            customBorder={customBorder}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            InputProps={{
                endAdornment:
                    type === 'password' ? (
                        <InputAdornment position="end">
                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ) : null,
            }}
        />
    );
};

export default InputComponent;
