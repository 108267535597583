import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChoiceComponent from '../../../components/ChoiceComponent';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import SignupLayout from 'layouts/frontOffice/StyledSignupLayout';
import Constants from 'common/Constants';

const SignupChoicePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [choice, setChoice] = useState('');

  const handleChoiceChange = (event) => {
    setChoice(event.target.value);
  };

  const handleNextClick = () => {
    if (choice === 'yes') {
      navigate(Constants.SITE_MAP.LOGIN);
    } else if (choice === 'no') {
      navigate(Constants.SITE_MAP.SIGN_UP);
    }
  };

  const customStyles1 = {
    backgroundColor: '#F3F4F6',
    color: '#6B7280',
    fontSize: '15px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    width: '300px',
    height: '56px',
    marginTop: '35px',
    marginBottom: '15px',
    borderRadius: '4px',
    uncheckedColor: '#6B7280',
    checkedColor: '#EC4899',
    iconSize: '24px'
  };
  const customStyles2 = {
    backgroundColor: '#F3F4F6',
    color: '#6B7280',
    fontSize: '15px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    width: '300px',
    height: '56px',
    marginBottom: '30px',
    borderRadius: '4px',
    uncheckedColor: '#6B7280',
    checkedColor: '#EC4899',
    iconSize: '24px'
  };

  return (
    <SignupLayout>
      <div style={{ width: '100%' }}>
        <Typography variant="h2">{t('signup.choiceTitle')}</Typography>
        <ChoiceComponent value="yes" label={t('signup.yes')} checked={choice === 'yes'} onChange={handleChoiceChange} customStyles={customStyles1} />
        <ChoiceComponent value="no" label={t('signup.no')} checked={choice === 'no'} onChange={handleChoiceChange} customStyles={customStyles2} />
        <Button variant="contained" color="primary" style={{ width: 250 }} onClick={handleNextClick}>{t('buttons.next')}</Button>
      </div>
    </SignupLayout>
  );
};

export default SignupChoicePage;
