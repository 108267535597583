import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import Lottie from 'lottie-react';

const ServiceCardComponent = ({ animationData, icon, title, bulletPoints, isAnimation ,isMobile}) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        maxWidth: '300px',
        margin: '0 auto',
        backgroundColor: 'transparent',
        border: isMobile ? "1px solid #B0B0B0" : "none",
        borderRadius: isMobile ? "20px" :"none",
        marginBottom: isMobile ? "20px" :"none",
        paddingLeft : isMobile ? "20px": "10px"

      }}
    >
      <Box sx={{ marginBottom: '10px' }}>
        {isAnimation ? (
          <Lottie animationData={animationData} loop={true} style={{ width: '100%', height: 'auto', maxWidth: '150px', margin: '0 auto' }} />
        ) : (
          <img src={icon} alt={title} style={{ width: '100%', height: 'auto', maxWidth: '150px', margin: '0 auto' }} />
        )}
      </Box>
      
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
        {title}
      </Typography>
      
      <List sx={{ textAlign: 'left', color: "#888" }}>
        {bulletPoints.map((point, index) => (
          <ListItem key={index}  sx={{ 
            paddingLeft: 0, 
            display: 'flex', 
            alignItems: 'flex-start', 
            position: 'relative',
            listStyleType: 'none',
            '&::before': {
              content: '"•"',
              color: "#888" ,
              fontWeight: 'bold',
              display: 'inline-block',
              width: '2em',
              marginRight: '0.5em',
              position: 'absolute',
              left: 0,
            },
          }}>
            <ListItemText primary={point} sx={{marginTop:"0px", marginBottom:"0px", paddingLeft: '1.5em'  }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ServiceCardComponent;
