import axios from "axios";
import Constants from "common/Constants";

export const uploadDocuments = async (data) => {
  const accessToken = localStorage.getItem('CLIENT_AUTH_TOKEN');

  let formData = new FormData();
  data.forEach((item) => {
    formData.append("files", item);
  });
  const response = await axios.post(Constants.APP_API_BASE_URL + "/documents/upload", formData, {
    headers: {
      "content-type": "multipart/form-data",
      'access-api-key': Constants.ACCESS_API_KEY_SAMPLE,
      'access-auth-token': accessToken
    },
  });
  return response;
};
