// project import
import clientMenu from './client-menu';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [clientMenu]
};

export default menuItems;
