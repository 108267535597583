import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import constants from '../common/Constants';

function getButtonBackgroundColor(type, disabled){
    switch(type){
        case constants.APP_BUTTON.CATEGORIES.SECONDARY_OUTLINED:
            return constants.APP_WHITE_COLOR;
        case constants.APP_BUTTON.CATEGORIES.DANGER_CONTAINED:
            return disabled ? constants.APP_ERROR_COLOR_DISABLED : constants.APP_ERROR_COLOR;
        case constants.APP_BUTTON.CATEGORIES.PRIMARY_CONTAINED:
        default:
            return disabled ? constants.APP_QUATERNARY_ACCENT_COLOR_DEFAULT : constants.APP_PRIMARY_ACCENT_COLOR_DEFAULT;
    }
};

const CustomButton = styled(Button)(({ theme, disabled, width, colour, border, backgroundColor, verticalMargin, horizontalMargin, customStyles, marginLeft, marginRight, height }) => ({
  width: width ?? 300,
  marginTop: verticalMargin ?? 0,
  marginBottom: verticalMargin ?? 0,
  marginLeft: horizontalMargin ?? (marginLeft ?? 0),
  marginRight: horizontalMargin ?? (marginRight ?? 0),
  height: height ?? 45,
  backgroundColor: backgroundColor,
  border: border ?? 'none',
  color: colour ?? constants.APP_WHITE_COLOR,
  fontFamily: 'Roboto, sans-serif',
  fontSize: 15,
  fontWeight: 'bolder',
  lineHeight: '14px',
  letterSpacing: '0.5px',
//   textTransform: 'uppercase',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  transition: 'background-color 0.4s ease',
  margin: customStyles?.margin || 0,
  '&:hover': {

      backgroundColor: disabled ? constants.APP_QUATERNARY_ACCENT_COLOR_DEFAULT : constants.APP_SECONDARY_ACCENT_COLOR_DEFAULT,
      border: 'none',
      color: constants.APP_WHITE_COLOR
  },
  '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: customStyles?.blurWidth || 300,
      height: customStyles?.blurHeight || 45,
      backgroundColor: constants.APP_QUATERNARY_ACCENT_COLOR_DEFAULT,
      zIndex: -1,
      filter: 'blur(5px) brightness(120%) drop-shadow(0 3px -2px #000000)',
      transform: 'translate(-50%, -50%)',
  },
  '&:hover::before, &:hover::after': {
      filter: 'blur(5px) drop-shadow(0 1px 0 #000000)',
  },
  '&[disabled]': {
      color: constants.APP_WHITE_COLOR,
      backgroundColor: constants.APP_QUATERNARY_ACCENT_COLOR_DEFAULT,
  },
}));


const ButtonComponent = ({ label, onClick, disabled, type, width, height, verticalMargin, horizontalMargin, marginLeft, marginRight, customStyles}) => {
    var color = constants.APP_WHITE_COLOR;
    var border = 'none';
    var backgroundColor = disabled ? constants.APP_QUATERNARY_ACCENT_COLOR_DEFAULT : constants.APP_PRIMARY_ACCENT_COLOR_DEFAULT;

    switch(type){
        case constants.APP_BUTTON.CATEGORIES.SECONDARY_OUTLINED:
            color = constants.APP_LINK_COLOR;
            border = '1px solid ' + constants.APP_LINK_COLOR;
            backgroundColor = constants.APP_WHITE_COLOR;
            break
        case constants.APP_BUTTON.CATEGORIES.DANGER_CONTAINED:
            //return disabled ? constants.APP_ERROR_COLOR_DISABLED : constants.APP_ERROR_COLOR;
            backgroundColor = constants.APP_ERROR_COLOR;
            break
        case constants.APP_BUTTON.CATEGORIES.PRIMARY_CONTAINED:
        default:
            break;
    }

    return (
        <CustomButton onClick={onClick} disabled={disabled} type={type} width={width} height={height} marginLeft={marginLeft} marginRight={marginRight}
            colour={color} border={border} backgroundColor={backgroundColor} 
            verticalMargin={verticalMargin} horizontalMargin={horizontalMargin} customStyles={customStyles}
        >
            {label}
        </CustomButton>
    ); 
};

export default ButtonComponent;
