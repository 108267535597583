import React, { useState } from 'react';
import Step9 from './Step9';
import { useTranslation } from 'react-i18next';
import SignupLayout from 'layouts/frontOffice/StyledSignupLayout';
import Details from './Invoice';

const RegisterPolicyProcess = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(9);

  const nextStep = (data) => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 9:
        return (
          <Step9
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 10:
        return (
          <Details
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <SignupLayout>
      {renderStep()}
    </SignupLayout>
  )
};

export default RegisterPolicyProcess;
